
import {
    CLEAR_DATA, CLEAR_FORM, SET_DATA, SET_FORM, SET_SERVICE_SAVING_STATE, SET_CATEGORIES
} from "./types";
import { addSupportedLocalesToData } from '@/helpers/helpers';



export default {

    setForm({ commit }, form) {
        commit(SET_FORM, form);

    },
    fetch({ commit }, options = {}) {
        const repository = this.app.config.globalProperties.$sq.repository;
        if (typeof options.cacheResults === 'undefined') {
            options.cacheResults = true;
        }

        return repository.fetch({
            url: `/service/${options.id}`
        })
            .then(response => {
                const serviceData = addSupportedLocalesToData(response);
                serviceData.categories = serviceData.categories.map(category => {
                    return category.id
                });
                commit(SET_DATA, serviceData);
                return serviceData;

            });

    },

    fetchCategories({ commit }, options = {}) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.fetch({
            url: '/service/categories'
        })
            .then(response => {
                if (options.cacheResults !== false) {
                    commit(SET_CATEGORIES, response);
                }

                return response;
            });
    },
    submit({ state, dispatch, commit, getters }) {
        const handleSubmit = state.form.handleSubmit(_ => {
            commit(SET_SERVICE_SAVING_STATE, true);
            let promise;
            const data = getters.formattedServiceData;

            if (state.serviceData.id) {
                promise = dispatch('editService', data);

            } else {
                promise = dispatch('createService', data);
            }

            return promise
                .then(_ => {
                    if (!state.serviceData.id) {
                        state.serviceData.id = response.data.id;
                    }
                })
                .then(_ => {
                    commit(SET_SERVICE_SAVING_STATE, false);
                })
                .catch(e => {
                    commit(SET_SERVICE_SAVING_STATE, false);
                    state.form.setErrors(e.data.validationErrors);

                    return e;
                });

        });

        return handleSubmit();
    },

    createService({ state }, data) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.createRecord(data, {
            url: `/service`,
        })
            .then(response => {
                state.serviceData.id = response.data.id;

            });
    },

    editService({ state }, data) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.editRecord(data, {
            url: `/service/${state.serviceData.id}`,
        });
    },

    clear({ commit }) {
        commit(CLEAR_DATA);
    },

    destroy({ commit }) {
        commit(CLEAR_DATA);
        commit(CLEAR_FORM);

    },
}