import { SET_DATA, SET_ITEMS_DATA, SET_ACTIVE_ITEM_ID, TOGGLE_QUOTE_ITEM_FORM, SET_ACTIVE_ITEM, CLEAR_ITEM_DATA, CLEAR_QUOTE_DATA, SET_QUOTE_SAVING_STATE } from "./types";
import { fromRFC3339ToDate } from "@rafaeljosem/sq-components/utilities";
import { TYPES } from "@/helpers/modules/service/service";
import store from "@/store/store";

export default {

    fetch({ commit }, options) {
        const repository = this.app.config.globalProperties.$sq.repository;
        const quoteData = {};
        return repository.fetch({
            url: `/quote/${options.id}`
        })
            .then(response => {
                Object.assign(quoteData, response);
                quoteData.contact.name = `${quoteData.contact.firstname} ${quoteData.contact.lastname}`;
                quoteData.dueDate = fromRFC3339ToDate(quoteData.dueDate);
                quoteData.requestDate = fromRFC3339ToDate(quoteData.requestDate);
                if (quoteData.deliveryDate) {
                    quoteData.deliveryDate = fromRFC3339ToDate(quoteData.deliveryDate);
                }
                commit(SET_DATA, quoteData);
                return quoteData;
            })
            .catch(e => {
                console.error(e);
            });

    },

    submit({ state, getters, dispatch, commit }, options = {}) {

        if (!state.form) {
            console.error('Quote form is not set');
            return;
        }
        const repository = this.app.config.globalProperties.$sq.repository;

        const handleSubmit = state.form.handleSubmit(_ => {
            if (options.setSavingState !== false) {
                commit(SET_QUOTE_SAVING_STATE, true);
            }
            let promise;
            const data = getters.formattedQuoteData;

            if (state.quoteData.id) {
                promise = dispatch('editQuote', data);

            } else {
                promise = dispatch('createQuote', data);
            }

            return promise
                .then(_ => {
                    if (!state.quoteData.id) {
                        state.quoteData.id = response.data.id;
                    }
                })
                .then(_ => {
                    commit(SET_QUOTE_SAVING_STATE, false);
                })
                .catch(e => {
                    commit(SET_QUOTE_SAVING_STATE, false);
                    state.form.setErrors(e.data.validationErrors)

                    return e;
                });

        });

        return handleSubmit();



    },

    createQuote({ state }, data) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.createRecord(data, {
            url: `/quote`,
        })
            .then(response => {
                state.quoteData.id = response.data.id;

            });
    },

    editQuote({ state }, data) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.editRecord(data, {
            url: `/quote/${state.quoteData.id}`,
        });

    },

    fetchItems({ commit }, options) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.fetch({
            url: `/quote/${options.id}/items`
        })
            .then(response => {

                commit(SET_ITEMS_DATA, response);
                return response;
            })
            .catch(e => {
                console.error(e);
            });

    },

    fetchQuoteItem({ commit, state }, options) {
        const repository = this.app.config.globalProperties.$sq.repository;

        return repository.fetch({
            url: `/quote/item/${options.id}`
        })
            .then(response => {

                const item = { ...response };
                item.salesTax = item.salesTax * 100;

                if (state.quoteData.service !== TYPES.IHT) {
                    commit(SET_ACTIVE_ITEM, item);
                    return response;

                }

                return store.dispatch('event/fetch', { id: item.event.id })
                    .then(_ => {
                        commit(SET_ACTIVE_ITEM, item);
                        return response;

                    });
            })

            .catch(e => {
                console.error(e);
            });
    },

    deleteItem({ state, dispatch }, id) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.removeRecord({
            url: `/quote/item/${id}`,
        })
            .then(_ => {
                return dispatch('fetchItems', { id: state.quoteData.id });
            });
    },

    toggleItemDialog({ commit, state }, id = null) {
        if (state.item.showItemDialog) {
            dispatch('clearItemData');
        }
        commit(SET_ACTIVE_ITEM_ID, id);
        commit(TOGGLE_QUOTE_ITEM_FORM);

    },

    openItemDialog({ commit }, id = null) {
        commit(SET_ACTIVE_ITEM_ID, id);
        commit(TOGGLE_QUOTE_ITEM_FORM, true);

    },
    closeItemDialog({ commit, dispatch }) {
        commit(SET_ACTIVE_ITEM_ID, null);
        commit(TOGGLE_QUOTE_ITEM_FORM, false);
        dispatch('clearItemData');
    },

    clearItemData({ commit }) {
        commit(CLEAR_ITEM_DATA);
        store.dispatch('event/clear');
    },

    clear({ commit, dispatch }) {

        commit(CLEAR_QUOTE_DATA);
        return dispatch('clearItemData');
    },
}