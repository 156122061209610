import { CLEAR_NCO_ITEM_DATA, SET_NCO_ITEM_SAVING_STATE, SET_DATA, SET_ITEM_DIALOG, SET_REGISTRATION_LIST } from "./types";
import store from '@/store/store';

export default {

    fetch({ commit }, options) {
        const repository = this.app.config.globalProperties.$sq.repository;
        const nco = {};
        return repository.fetch({
            url: `/qms/nco/item/${options.id}`
        })
            .then(response => {
                Object.assign(nco, response);
                commit(SET_DATA, nco);
                return nco;
            })
            .catch(e => {
                console.error(e);
            });

    },

    submit({ state, getters, dispatch, commit }) {

        if (!state.form) {
            console.error('NCO Item form is not set');
            return;
        }
        const repository = this.app.config.globalProperties.$sq.repository;

        const handleSubmit = state.form.handleSubmit(_ => {
            commit(SET_NCO_ITEM_SAVING_STATE, true);
            let promise;
            const data = getters.formattedNCOItemData;

            if (state.itemData.id) {
                promise = dispatch('editNCOItem', data);

            } else {
                promise = dispatch('createNCOItem', data);
            }

            return promise
                .then(response => {
                    if (!state.itemData.id) {
                        state.itemData.id = response.data.id;
                    }

                    return store.dispatch('qms/nco/fetchItems');
                })
                .then(_ => {
                    commit(SET_NCO_ITEM_SAVING_STATE, false);
                    return store.dispatch('sq/toast/notifySuccess', {
                        title: 'Success',
                        detail: 'Done!'
                    });
                })
                .catch(e => {
                    commit(SET_NCO_ITEM_SAVING_STATE, false);
                    state.form.setErrors(e.data.validationErrors);

                    return e;
                });

        });

        return handleSubmit();
    },

    createNCOItem({ state }, data) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.createRecord(data, {
            url: `/qms/nco/item`,
            showSuccessMessage: false
        })
            .then(response => {
                state.itemData.id = response.data.id;

            });
    },

    editNCOItem({ state }, data) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.editRecord(data, {
            url: `/qms/nco/item/${state.itemData.id}`,
            showSuccessMessage: false
        });

    },

    delete({ state }, options) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.removeRecord( {
            url: `/qms/nco/item/${options.id}`,
            showSuccessMessage: false
        })
        .then(_ => {
            return store.dispatch('qms/nco/fetchItems');
        })
        .then(_ => {
            store.dispatch('sq/toast/notifySuccess', {
                title: "",
                detail: ""
            })
        });

    },

    fetchRegistrations({ commit }, options) {
        return store.dispatch('event/fetchRegistrations', { id: options.id, cache: false })
            .then(orders => {
                orders.forEach(order => {
                    order.companyName = order.company.name
                });
                commit(SET_REGISTRATION_LIST, orders)
                return orders;
            })
            .catch(e => {
                console.error(e);
            });

    },

    openItemDialog({ commit }) {
        return commit(SET_ITEM_DIALOG, true);

    },

    closeItemDialog({ commit }) {
        return commit(SET_ITEM_DIALOG, false);
    },


    clear({ commit, state }) {

        commit(CLEAR_NCO_ITEM_DATA);
        state.form.resetForm();
    },
}