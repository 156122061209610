import { TOGGLE_TEMPLATE_DIALOG, SET_TEMPLATE_LIST } from "./types"

export default {
    namespaced: true,
    actions: {
        toggleTemplateDialog({ commit }) {
            commit(TOGGLE_TEMPLATE_DIALOG, null);
        },
        closeTemplateDialog({ commit }) {
            commit(TOGGLE_TEMPLATE_DIALOG, false);
        },
        openTemplateDialog({ commit }) {
            commit(TOGGLE_TEMPLATE_DIALOG, true);
        },

        fetchTemplates({ commit }) {
            const repository = this.app.config.globalProperties.$sq.repository;
            return repository.fetch({
                url: `/mail/template/list`
            })
                .then(response => {
                    commit(SET_TEMPLATE_LIST, response);
                })
                .catch(e => {
                    console.error(e);
                });
        },

        clear({ commit }) {
            commit(SET_TEMPLATE_LIST, []);
        }
    },
    state: {
        showTemplateDialog: false,
        templates: [],
    },
    getters: {},
    mutations: {
        [TOGGLE_TEMPLATE_DIALOG](state, value) {
            if (value !== null) {
                state.showTemplateDialog = value;
                return;
            }
            state.showTemplateDialog = !state.showTemplateDialog;
        },
        [SET_TEMPLATE_LIST](state, templates) {
            state.templates = templates;
        },
    },
}