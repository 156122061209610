export const TYPES = {
    TRAINING: 1,
    CONSULTING: 2,
    AUDITING: 3,
    PUBLIC_TRAINING: 4,
    IHT: 5,
    MISC: 6,
    SELF_PACED_ONLINE_TRAINING: 7

};


export const parseService = function (type) {

    switch (parseInt(type)) {
        case TYPES.TRAINING:
            return `forms.service.type.training`;
        case TYPES.CONSULTING:
            return `forms.service.type.consulting`;
        case TYPES.AUDITING:
            return `forms.service.type.auditing`;
        case TYPES.PUBLIC_TRAINING:
            return `forms.service.type.publicTraining`;
        case TYPES.IHT:
            return `forms.service.type.iht`;
        case TYPES.MISC:
            return `forms.service.type.misc`;
        case TYPES.SELF_PACED_ONLINE_TRAINING:
            return `forms.service.type.selfPacedOnlineTraining`;
        default:
            throw new Error('Invalid service');
    }

};


export const getServiceTypes = function (includeOnly = []) {

    const types = [];

    for (const key in TYPES) {

        types.push({
            name: parseService(TYPES[key]),
            value: TYPES[key]
        })
    }

    if (includeOnly.length > 0) {
        return types.filter(type => includeOnly.includes(type.value));
    }
    return types;
};