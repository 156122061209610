
import store from "@/store/store";
export default {

    formattedInstructorData(state) {
        const formattedData = { ...state.instructorData };
        formattedData.contact = store.getters['contact/formattedContactData'];

        const formData = new FormData();
        const file = formattedData.imageFile ? formattedData.imageFile[0] : null;
        delete formattedData.imageFile;
        delete formattedData.alias;
        formData.append("json", JSON.stringify(formattedData));
        if (file) {
            formData.append(`imageFile`, file, file.name);
        }
        return formData;
    }

};