
export default {

    addSection({ state, dispatch }, { index, payload }) {
        let positionUpdated = false;
        payload.data.position = index;
        payload.data.course = state.courseData.id;
        if (index !== state.sections.length) {
            positionUpdated = true;
            for (let i = index; i < state.sections.length; i++) {
                state.sections[i].position = i + 1;
            }
        }

        if (!positionUpdated) {
            return dispatch('createSection', {
                data: payload.data,
                options: { url: "/course/sections", },
                payload
            });
        }

        return dispatch('editSection', {
            data: { sections: [...state.sections, payload.data] },
            options: { url: `/course/${state.courseData.id}`, patch: true },
            index,
            payload
        });
    },

    createSection({ state }, { data, options, payload }) {
        const repository = this.app.config.globalProperties.$sq.repository;
        repository.createRecord(data, options)
            .then((response) => {
                payload.data.id = response.data.id;
                state.sections.push(payload.data);
            });
    },

    editSection({ state, dispatch }, { data, options, index, payload }) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.editRecord(data, options)
            .then(_ => {
                if (index) {
                    state.sections.splice(index, 0, payload.data);
                }
            })
            .then(_ => {
                return dispatch('loadSections');
            });

    },

    loadSections({ state }) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.fetch({
            url: `course/${state.courseData.id}/sections`
        }).then((response) => {
            for (const key in response) {
                state.sections[key] = response[key];
            }

            return state.sections;
        });

    }
};