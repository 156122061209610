export const SET_DATA = 'SET_EVENT_DATA';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_FORM = 'SET_FORM';
export const CLEAR_DATA = 'CLEAR_EVENT_DATA';
export const CLEAR_FORM = 'CLEAR_EVENT_FORM';
export const TOGGLE_REGISTRATION_FORM = 'TOGGLE_REGISTRATION_FORM';
export const SET_ACTIVE_REGISTRATION = 'SET_ACTIVE_REGISTRATION';
export const SET_REGISTRATION_LIST = 'SET_REGISTRATION_LIST';
export const SET_ATTENDEE_LIST = 'SET_ATTENDEE_LIST';
export const SET_MAIL_LIST = 'SET_MAIL_LIST';
export const SET_ACTIVE_MAIL = 'SET_ACTIVE_MAIL';
export const TOGGLE_MAIL_FORM = 'TOGGLE_MAIL_FORM';
export const SET_INSTRUCTOR_LIST = 'SET_INSTRUCTOR_LIST';
export const defaultEventData = {
    instructors: [],
    type: null,
    maxAttendees: 20,
    course: {
        name: "",
        id: null
    },
    company: {}

};