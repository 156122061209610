import {
    SET_DATA, CLEAR_ACCOUNT_DATA, defaultData, SET_ACCOUNT_SAVING_STATE, SET_FORM
} from "./types";

export default {

    [SET_DATA](state, data) {
        state.accountData = data;
    },
    [CLEAR_ACCOUNT_DATA](state) {
        state.accountData = { ...defaultData };
    },

    [SET_ACCOUNT_SAVING_STATE](state, status) {
        state.isSaving = status;
    },
    [SET_FORM](state, form) {
        state.form = form;
    }

};