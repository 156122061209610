import { defaultData, defaultItemData } from "./types";

export default {
    invoiceData: { ...defaultData },
    invoiceItems: [],
    showCreditNoteList: false,
    creditNotes: [],
    isSaving: false,
    form: null,
    ncfStatus: {},
    item: {
        registrations: [],
        showItemDialog: false,
        activeItemId: null,
        activeItemData: { ...defaultItemData }
    }
}