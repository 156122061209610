
import { VideoManagerFactory } from "@/helpers/video-manager.js";

const getUrl = function (dataModel) {

    const urls = {};
    if (dataModel.type === 'lesson') {
        urls.post = 'course/sections/lessons';

    }
    if (dataModel.type === 'exam') {
        urls.post = 'course/sections/exams';
    }

    if (dataModel.id) {
        urls.post += `/${dataModel.id}`
    };

    return urls;
};

export default {

    async fetch({ }, options = {}) {
        const repository = this.app.config.globalProperties.$sq.repository;
        const response = await repository.fetch({
            url: options.url,
            loadingContainer: options.loadingContainer
        });

        return response;

    },

    async submit({ }, payload) {
        const repository = this.app.config.globalProperties.$sq.repository;

        try {
            const url = getUrl(payload.data);

            if (payload.data.id) {
                const repository = this.app.config.globalProperties.$sq.repository;
                return await repository.editRecord(payload.data, {
                    url: url.post,
                    patch: true
                });

            }

            return await repository.createRecord(payload.data, {
                url: url.post
            });
        } catch (e) {
            if (e.data && e.data.validationErrors) {
                e.errorList = e.data.validationErrors;
            }
            throw e;
        }

    },

    async uploadVideo({ }, payload) {
        const repository = this.app.config.globalProperties.$sq.repository;
        const vimeo = VideoManagerFactory.create("vimeo", repository);

        return await vimeo
            .upload(payload.file, {
                name: payload.data.name,
                description: payload.data.description,
                privacy: {
                    //view: "unlisted",
                    embed: "whitelist",
                },
            });
    },

    async delete({ }, payload) {

        const repository = this.app.config.globalProperties.$sq.repository;
        const vimeo = VideoManagerFactory.create("vimeo", repository);
        const removeRecord = async function () {
            return await repository.removeRecord({
                url: `course/sections/${payload.data.type}s/${payload.data.id}`,
            });
        }

        try {

            if (payload.data.type === 'lesson') {
                await vimeo.delete(payload.data.url)
            }
            return await removeRecord();
        } catch (e) {

            // try to delete from db anyway
            const response = await removeRecord();
            if (e.statusCode === 404) {
                return response;
            }

            console.error(e);
            return response;
        }

    },
};