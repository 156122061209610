/*=========================================================================================
  File Name: i18n.js
  Description: i18n configuration file. Imports i18n data.
  ----------------------------------------------------------------------------------------
  Project Name: SQMS Frontend
  Author: Rafael J. Mateo C.
==========================================================================================*/


import { createI18n } from 'vue-i18n';
import i18nData from './i18nData';
import { configure } from 'vee-validate';
import { localize, setLocale } from '@vee-validate/i18n';
import en from '@vee-validate/i18n/dist/locale/en.json';
import es from '@vee-validate/i18n/dist/locale/es.json';

const defaultLocale = 'es';
const i18n = createI18n({
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  messages: i18nData,
  global: true
});



//Vee validate locale config

configure({
  generateMessage: localize({
    en,
    es,
  }),
});

setLocale(defaultLocale);

export default i18n;
