<template>
  <div  v-for="(target, index) in targets" :key="index">
    <transition v-if="isActive(target)" name="fade">
      <teleport  :to="target">
        <div v-if="getOptions(target).mode === 'overlay'" class="sq-overlay">
          <div class="sq-overlay-inner">
            <div class="sq-overlay-content">
              <progress-spinner style="width: 50px; height: 50px" />
            </div>
          </div>
        </div>
        <div v-else class="tw-flex tw-content-center tw-justify-center">
          <progress-spinner style="width: 50px; height: 50px" />
        </div>
      </teleport>
    </transition>
  </div>
</template>
<script>

import store from "@/store/store";
import { computed } from "vue";
import ProgressSpinner from "primevue/progressspinner";

export default {

  components: {
    ProgressSpinner,
  },
  setup() {
    const isActive = function(target) {
      return store.state.sq.loader.isLoading[target] === true

    };
  
    const targets = computed(_ => {
      return store.getters['sq/loader/targets'];
    })

    const getOptions = (target) => {
      return store.state.sq.loader.options[target];
    };
    
    return {
      isActive,
      targets,
      getOptions,
    };
  },
};
</script>

<style>
.sq-loader-hide > * {
  display: none;
}
.sq-loader-hide > *:last-child {
  display: flex;
}

.sq-loader-overlay {
  position: relative;
}

.sq-overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.4);
}

.sq-overlay-inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.sq-overlay-content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: #1976d2;
  }
  40% {
    stroke: #1976d2;
  }
  66% {
    stroke: #1976d2;
  }
  80%,
  90% {
    stroke: #1976d2;
  }
}
</style>