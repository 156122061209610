import {
    SET_DATA, SET_ITEMS_DATA, SET_ACTIVE_ITEM_ID, TOGGLE_CREDIT_NOTE_ITEM_FORM, SET_ACTIVE_ITEM, CLEAR_CREDIT_NOTE_DATA, CLEAR_ITEM_DATA,
    defaultData, defaultItemData, SET_CREDIT_NOTE_SAVING_STATE, SET_FORM, SET_REGISTRATION_LIST, SET_ITEM_SERVICE_TYPE
} from "./types";

export default {

    [SET_DATA](state, data) {
        state.creditNoteData = data;
    },

    [SET_ITEMS_DATA](state, data) {
        state.creditNoteItems = data;
    },
    [SET_ACTIVE_ITEM_ID](state, id) {
        state.item.activeItemId = id;

    },
    [SET_ACTIVE_ITEM](state, item) {
        state.item.activeItemData = item;
    },
    [TOGGLE_CREDIT_NOTE_ITEM_FORM](state, status = null) {
        if (status !== null) {
            state.item.showItemDialog = status;
            return;
        }
        state.item.showItemDialog = !state.item.showItemDialog;
    },
    [CLEAR_CREDIT_NOTE_DATA](state) {
        state.form.resetForm();
        state.creditNoteData = { ...defaultData };
        state.creditNoteItems = [];
        state.ncfStatus = {};
    },
    [CLEAR_ITEM_DATA](state) {
        state.item.activeItemData = { ...defaultItemData };
        state.item.activeItemId = null;

    },

    [SET_CREDIT_NOTE_SAVING_STATE](state, status) {
        state.isSaving = status;
    },
    [SET_FORM](state, form) {
        state.form = form;
    },
    [SET_REGISTRATION_LIST](state, registrations) {
        state.item.registrations = registrations;
    },
    [SET_ITEM_SERVICE_TYPE](state, type) {
        state.item.activeItemData.serviceType = type;
    }

};