import actions from './actions';
import state from './state';
import mutations from './mutations';
import getters from './getters';
import itemModule from './item/store';
import actionModule from './action/store';

export default {
    namespaced: true,
    actions,
    state,
    mutations,
    getters,
    modules: {
        item: itemModule,
        action: actionModule
    }
}