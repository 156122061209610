import { OPEN_INVOICE_DIALOG, CLOSE_INVOICE_DIALOG, CLEAR_INVOICES } from "./types";

export default {

    [OPEN_INVOICE_DIALOG](state) {
        state.showInvoiceDialog = true;
    },
    [CLOSE_INVOICE_DIALOG](state) {
        state.showInvoiceDialog = false;
    },
    [CLEAR_INVOICES](state) {
        state.invoices = [];
        state.showInvoiceDialog = false;
    },
}