import { CLEAR_CONTACT_DATA, SET_CONTACT_SAVING_STATE, SET_DATA } from "./types";

export default {

    fetch({ commit }, options) {
        const repository = this.app.config.globalProperties.$sq.repository;
        const contact = {};
        return repository.fetch({
            url: `/contact/${options.id}`
        })
            .then(response => {
                Object.assign(contact, response);
                commit(SET_DATA, contact);
                return contact;
            })
            .catch(e => {
                console.error(e);
            });

    },

    submit({ state, getters, dispatch, commit }) {

        if (!state.form) {
            console.error('Contact form is not set');
            return;
        }
        const repository = this.app.config.globalProperties.$sq.repository;

        const handleSubmit = state.form.handleSubmit(_ => {
            commit(SET_CONTACT_SAVING_STATE, true);
            let promise;
            const data = getters.formattedContactData;

            if (state.contactData.id) {
                promise = dispatch('editContact', data);

            } else {
                promise = dispatch('createContact', data);
            }

            return promise
                .then(_ => {
                    if (!state.contactData.id) {
                        state.contactData.id = response.data.id;
                    }
                })
                .then(_ => {
                    commit(SET_CONTACT_SAVING_STATE, false);
                })
                .catch(e => {
                    commit(SET_CONTACT_SAVING_STATE, false);
                    state.form.setErrors(e.data.validationErrors);

                    return e;
                });

        });

        return handleSubmit();
    },

    createContact({ state }, data) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.createRecord(data, {
            url: `/contact`,
        })
            .then(response => {
                state.contactData.id = response.data.id;

            });
    },

    editContact({ state }, data) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.editRecord(data, {
            url: `/contact/${state.contactData.id}`,
        });

    },

    becomeInstructor({ state, dispatch }, options = {}) {
        const repository = this.app.config.globalProperties.$sq.repository;
        if (!options.id) {
            options.id = state.contactData.id;
        }
        return repository.createRecord(options.data, {
            url: `/contact/${options.id}/become-instructor`,
        })
            .then(_ => {
                return dispatch('fetch', { id: state.contactData.id })
            });

    },

    becomeEmployee({ state, dispatch }, options = {}) {
        const repository = this.app.config.globalProperties.$sq.repository;
        if (!options.id) {
            options.id = state.contactData.id;
        }
        return repository.createRecord(options.data, {
            url: `/contact/${options.id}/become-employee`,
        })
            .then(_ => {
                return dispatch('fetch', { id: state.contactData.id });
            });

    },

    clear({ commit, state }) {

        commit(CLEAR_CONTACT_DATA);
        if (state.form) {
            state.form.resetForm();
        }
    },
}