import { format, parse } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import Axios from '@/axios';
import store from "@/store/store";
import { FileError } from "@/helpers/errors";
import useLocales from '@/composables/i18n/useI18n.js';

/**
    * @param {Array} imagesData
    * @param {Array} quality
    * @param {String} url
    */
export const getImages = function (imagesData, quality, url) {
    const promises = [];
    const images = {};

    quality.forEach(imageQuality => {
        const filteredImages = imagesData.filter(
            (image) => image.quality === imageQuality
        );

        const image = filteredImages[0];

        if (!image || !image.filename) {
            return;
        }

        const promise = getImage(`${url}/${image.filename}`).then(data => {
            images[image.quality] = data;
        });

        promises.push(promise);

    })

    return Promise.all(promises)
        .then(response => {
            return images;
        });

};

export const getImage = function (url) {

    return Axios.get(url, {
        autoCatchErrors: false,
        responseType: "arraybuffer",
        headers: {
            "Content-Type": "application/json",
            Accept: "image/jpeg",
        },
    })
        .then((response) => {
            return window.URL.createObjectURL(new Blob([response.data]));
        })
        .catch((error) => console.error(error));

};

export const fromRFC3339ToDate = function (date) {
    return utcToZonedTime(parse(date, 'yyyy-MM-dd\'T\'HH:mm:ssXXX', new Date()))

};

export const toRFC3339FromDate = function (date) {
    return format(date, 'yyyy-MM-dd kk:mm:ssXXX')

};

export const formatDate = function (date, withTime = false) {
    if (withTime) {
        return format(date, 'dd/MM/yy hh:mm a');

    }
    return format(date, 'dd/MM/yy');
};

export const formatDateFromISO = function (date) {
    return format(parse(date, "yyyy-MM-dd", new Date()), "dd/MM/yy");
};


export const getCurrencyList = function () {

    return [
        { code: 'USD', currency: 'forms.currency.usd' },
        { code: 'DOP', currency: 'forms.currency.dop' },
    ];
};

/**
 * 
 * @param {String} code 
 * @return {String}
 */
export const parseCurrency = function (code) {

    switch (code.toLowerCase()) {
        case 'dop':
            return 'forms.currency.dop';

        case 'usd':
            return 'forms.currency.usd';

        default:
            return 'forms.currency.invalid';
    }
};


export const formatCurrency = function (value) {

    return Number(value).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
    });

}

export const formatPrice = function (number, locale = 'en-US') {
    return new Intl.NumberFormat(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
};

export const fetchFile = function (url, repository, loadingContainer, options = {}) {
    if (loadingContainer) {
        store.dispatch('sq/loader/startLoading', {
            container: loadingContainer,
            mode: 'overlay'
        });
    }
    const downloadfile = function (filename, url) {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

    }
    return repository.fetchFile({
        url,
        ...options
    })
        .then(response => {
            try {
                const url = window.URL.createObjectURL(response.data);
                if (loadingContainer) {
                    store.dispatch('sq/loader/stopLoading', loadingContainer);
                }

                if (response.headers && response.headers['content-type'] === 'application/pdf') {
                    window.open(url, '_blank');
                    return;
                }

                if (!response.headers['content-disposition']) {
                    throw new Error('File content-disposition header is not specified. File content disposition is needed for getting the filename.');
                }

                const filename = response.headers['content-disposition'].split("=")[1];
                downloadfile(filename, url);
            } catch (e) {
                throw new FileError(e.message);

            }
        })
        .catch(e => {
            if (loadingContainer) {
                store.dispatch('sq/loader/stopLoading', loadingContainer);
            }
            console.error(e);
            throw e;

        });

};

export const getMonths = function (t) {

    const months = {
        1: "jan",
        2: "feb",
        3: "mar",
        4: "apr",
        5: "may",
        6: "jun",
        7: "jul",
        8: "aug",
        9: "sept",
        10: "oct",
        11: "nov",
        12: "dec",
    };

    return months;

}
export const addSupportedLocalesToData = function (data) {
    const { supportedDataLocales } = useLocales();
    const newData = JSON.parse(JSON.stringify(data));
    supportedDataLocales.forEach((locale) => {
        if (!data.translations[locale]) {
            newData.translations[locale] = {};
        }
    });

    return newData;
};
