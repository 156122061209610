
import store from "@/store/store";
export default {

    formattedNCOItemData(state) {
        const formattedData = { ...state.itemData };

        if (formattedData.service) {
            formattedData.service = formattedData.service.id;
        }

        if (formattedData.event) {
            formattedData.event = formattedData.event.id;
            formattedData.order = formattedData.order.id;
        }

        formattedData.nco = store.state.qms.nco.ncoData.id;

        return formattedData;

    }

};