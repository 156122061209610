import router from '@/router.js';

export default {

    createInvoice({ }, options) {

        if (!options.id) {
            throw new Error('You must specify an id for creating an invoice from registration');
        }
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.createRecord({}, {
            url: `/event/registration/create-invoice/${options.id}`,
        });
    },

    async getInvoices({state}, options) {

        if (!options.id) {
            throw new Error('You must specify an id for creating an invoice from registration');
        }

        const repository = this.app.config.globalProperties.$sq.repository;
       const invoices = await repository.fetch({
            url: `/event/registration/get-invoices/${options.id}`
        });

        state.invoices = invoices.invoices;      
        if (!invoices) {
            return null;
        }

        if (invoices.count > 0) {
            state.showInvoiceDialog = true;
            return invoices;

        }

        return router.push({path: `/invoice/${invoices.invoices[0].id}`});


    },

    cancelRegistration({}, options) {

        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.removeRecord( {
            url: `/event/registration/${options.id}`,
        });
    },

    reactivateRegistration({}, options) {

        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.createRecord({}, {
            url: `/event/registration/${options.id}`,
        });
    },

    sendCertificates({}, options) {

        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.createRecord({}, {
            url: `/event/registration/${options.id}/certificates`,
        });

    },

    sendCertificateToAttendee({}, options)  {

        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.createRecord({}, {
            url: `/event/registration/attendee/${options.id}/certificate`,
        });

    }
}