

export default {

    formattedContactData(state) {
        const formattedData = { ...state.contactData };
        if (formattedData.company) {
            formattedData.company = formattedData.company.id;
        }
        delete formattedData.employee;
        delete formattedData.instructor;
        return formattedData;

    }

};