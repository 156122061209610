import { createStore } from 'vuex';
import eventModule from './modules/event/store';
import surveyModule from './modules/survey/store';
import templateModule from './modules/template/store';
import quoteModule from './modules/quote/store';
import invoiceModule from './modules/invoice/store';
import creditNoteModule from './modules/credit/store';
import accountModule from './modules/account/store';
import contactModule from './modules/contact/store';
import instructorModule from './modules/instructor/store';
import courseModule from './modules/course/store';
import serviceModule from './modules/service/store';
import qualityModule from './modules/qms/store';
import actions from './actions';
import getters from './getters';


// Create a new store instance.
export default createStore({
    state: {},
    mutations: {},
    actions,
    getters,
    modules: {
        event: eventModule,
        survey: surveyModule,
        template: templateModule,
        quote: quoteModule,
        invoice: invoiceModule,
        credit: creditNoteModule,
        account: accountModule,
        contact: contactModule,
        instructor: instructorModule,
        course: courseModule,
        service: serviceModule,
        qms: qualityModule
    },
});