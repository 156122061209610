import {
    SET_DATA, CLEAR_ACTION_DATA, defaultData, SET_ACTION_SAVING_STATE,SET_ACTION_DELETING_STATE, SET_FORM, OPEN_ACTION_DIALOG, CLOSE_ACTION_DIALOG,
} from "./types";

export default {

    [SET_DATA](state, data) {
        state.actionData = data;
    },
    [CLEAR_ACTION_DATA](state) {
        state.actionData = { ...defaultData };
    },

    [SET_ACTION_SAVING_STATE](state, status) {
        state.isSaving = status;
    },
    [SET_ACTION_DELETING_STATE](state, status) {
        state.isDeleting = status;
    },

    [SET_FORM](state, form) {
        state.form = form;
    },

    [OPEN_ACTION_DIALOG](state) {
        state.showActionDialog = true;
    },
    [CLOSE_ACTION_DIALOG](state) {
        state.showActionDialog = false;
    },

};