import { ref } from "vue";
import store from "@/store/store";
import { computed } from "@vue/reactivity";
import { defineRule } from "vee-validate";
import { required } from "@vee-validate/rules";
import { deleteInvoiceItem } from "@/pages/invoice/composable/useInvoiceItemRepository";

const itemData = computed(_ => {
    return store.state.credit.item.activeItemData;
});


export const validationSchema = {
    creditNote: null,
    id: null,
    price: "required",
    quantity: "required",
    serviceDescription: null,
    salesTax: "required",
}

defineRule("required", required);

export default function ({ t }, creditRepository = null, confirm = null) {

    const isSaving = ref(false);
    const isDeleting = ref(false);

    const submit = async function () {
        isSaving.value = true;
        const results = await store.dispatch('credit/submitItem');
        isSaving.value = false;
        return results;

    };

    const deleteItem = async function (id) {
        try {
            const results = await deleteInvoiceItem({
                id,
                confirmFunction: confirm,
                repository: creditRepository,
                deleteActionURL: 'credit/deleteItem',
                closeDialogURL: 'credit/closeItemDialog',
                confirmDialog: {
                    text: t('dialogs.creditNote.ncf.deleteItem.text'),
                    header: t('dialogs.creditNote.ncf.deleteItem.header')
                }
            });
            isDeleting.value = false;
            return results;
        }
        catch (e) {
            isDeleting.value = false;
            console.error(e);
            return;
        };
    };

    return {
        submit,
        deleteItem,
        itemData,
        isSaving,
        isDeleting
    }
};