
import { toRFC3339FromDate } from "@rafaeljosem/sq-components/utilities";
export default {

    formattedCAPAData(state) {
        const formattedData = { ...state.capaData };
        formattedData.requestDate = toRFC3339FromDate(formattedData.requestDate);
        formattedData.estClosureDate = toRFC3339FromDate(formattedData.estClosureDate);

        if (formattedData.process) {
            formattedData.process = formattedData.process.id;
        }

        if (formattedData.requestedBy) {
            formattedData.requestedBy = formattedData.requestedBy.id;
        }

        if (formattedData.source) {
            formattedData.source = formattedData.source.id;
        }

        return formattedData;

    }

};