import { fromRFC3339ToDate, toRFC3339FromDate } from "@rafaeljosem/sq-components/utilities";

/**
 * 
 * @param {Object} options 
 * @param {String} options.url
 * @returns {Promise}
 */
export const fetch = function (options) {
    const repository = this.app.config.globalProperties.$sq.repository;
    const actionData = {};
    return repository.fetch({
        url: options.url
    })
        .then(response => {
            Object.assign(actionData, response);
            actionData.employee.name = `${actionData.employee.firstname} ${actionData.employee.lastname}`;
            actionData.dueDate = fromRFC3339ToDate(actionData.dueDate);
            return actionData;
        })
        .catch(e => {
            console.error(e);
        });
};


/**
 * 
 * @param {Object} options 
 * @param {Object} options.actionData
 * @param {Object} options.url
 * @param {String} options.url.create
 * @param {String} options.url.edit
 * @returns {Promise}
 */
export const submit = function (options) {

    const repository = this.app.config.globalProperties.$sq.repository;

    const createAction = function (data) {
        return repository.createRecord(data, {
            url: options.url.create,
            showSuccessMessage: false
        })
            .then(response => {
                options.actionData.id = response.data.id;

            });
    };

    const editAction = function (data) {
        return repository.editRecord(data, {
            url: options.url.edit,
            showSuccessMessage: false
        });

    };

    let promise;
    const data = formatAction(options.actionData);

    if (data.id) {
        promise = editAction(data);

    } else {
        promise = createAction(data);
    }

    return promise
        .catch(e => {
            if (e.data && e.data.validationErrors) {
                e.errorList = e.data.validationErrors;
            }
            throw e;
        });


};

/**
 * 
 * @param {Array} actions 
 * @returns {Array}
 */
export const formatActions = function (actions) {

    if (!Array.isArray(actions)) {
        console.error('actions must be an array');
    }

    actions.forEach(action => {
        action.dueDate = fromRFC3339ToDate(action.dueDate);
        action.employee.name = action.employee.firstname + ' ' + action.employee.lastname;

    });

    return actions;
};

/**
 * 
 * @param {Object} actionData 
 * @returns 
 */
const formatAction = function (actionData) {
    const data = JSON.parse(JSON.stringify(actionData));

    if (actionData.dueDate) {
        data.dueDate = toRFC3339FromDate(actionData.dueDate);
    }

    data.employee = actionData.employee.id;
    delete data.actionPlan;

    return data;

};