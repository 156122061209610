import { defaultCourseData } from "./types";


export default {
    courseData: JSON.parse(JSON.stringify(defaultCourseData)),
    form: null,
    formLanguage: {},
    isSaving: false,
    categories: [],
    sections: [],
}