export const SET_DATA = 'SET_COURSE_DATA';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_FORM = 'SET_FORM';
export const CLEAR_DATA = 'CLEAR_COURSE_DATA';
export const CLEAR_FORM = 'CLEAR_COURSE_FORM';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_COURSE_SAVING_STATE = 'SET_COURSE_SAVING_STATE';
export const SET_FORM_LANGUAGE = 'SET_FORM_LANGUAGE';
export const defaultCourseData = {

    id: null,
    categories: [],
    images: [],
    translations: {
        es: { name: null, alias: null, showWebsite: false, isDiscontinued: false, description: null },
        en: { name: null, alias: null, showWebsite: false, isDiscontinued: false, description: null },
    },
    courseType: null,
    duration: null,
    priceUSD: null,
    priceDOP: null,
    prerequisites: null,


};

export const createDataModel = function () {

    const model = { ...defaultCourseData };
    supportedDataLocales.forEach(locale => {
        model.course.translations[locale] = {
            name: null,
            alias: null,
            showWebsite: false,
            isDiscontinued: false,
            description: null,
            objectives: null,
            content: null,
            designedFor: null,
            metadesc: null,
            metakey: null

        }
    })

    return model;

};