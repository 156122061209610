export default {

    formattedCourseData(state) {
        const files = state.courseData.imageFiles;
        const formattedData = JSON.parse(JSON.stringify(state.courseData));
        delete formattedData.imageFiles;
        const formData = new FormData();
        formData.append("json", JSON.stringify(formattedData));


        for (const fileKey in files) {
            const file = files[fileKey];
            formData.append(`${fileKey}[]`, file, file.name);
        }

        return formData;

    }

}