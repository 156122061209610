export const defaultData = {
    company: {

    },
    contact: {

    },
    ncfDate: new Date(),
    status: null,
    currency: null,

};

export const defaultItemData = {
    salesTax: 0.0,
    price: null,
    quantity: null,
    serviceDescription: null,
};

export const SET_DATA = 'SET_DATA';
export const SET_CREDIT_NOTE_SAVING_STATE = 'SET_QUOTE_SAVING_STATE';
export const SET_ITEMS_DATA = 'SET_ITEMS_DATA';
export const SET_FORM = 'SET_FORM';
export const SET_ACTIVE_ITEM_ID = 'SET_ACTIVE_ITEM_ID';
export const SET_ACTIVE_ITEM = 'SET_ACTIVE_ITEM';
export const TOGGLE_CREDIT_NOTE_ITEM_FORM = 'TOGGLE_QUOTE_ITEM_FORM';
export const CLEAR_CREDIT_NOTE_DATA = 'CLEAR_QUOTE_DATA';
export const CLEAR_ITEM_DATA = 'CLEAR_ITEM_DATA';
export const SET_REGISTRATION_LIST = 'SET_REGISTRATION_LIST';
export const SET_ITEM_SERVICE_TYPE = 'SET_ITEM_SERVICE_TYPE';