import {
    SET_DATA, CLEAR_NCO_ITEM_DATA, defaultData, SET_NCO_ITEM_DELETING_STATE, SET_NCO_ITEM_SAVING_STATE, SET_FORM, SET_SERVICE_DIALOG, SET_ITEM_DIALOG, SET_REGISTRATION_LIST, SET_ITEM_SERVICE_TYPE
} from "./types";

export default {

    [SET_DATA](state, data) {
        state.itemData = data;
    },
    [CLEAR_NCO_ITEM_DATA](state) {
        state.itemData = { ...defaultData };
    },

    [SET_NCO_ITEM_SAVING_STATE](state, status) {
        state.isSaving = status;
    },
    [SET_NCO_ITEM_DELETING_STATE](state, status) {
        state.isDeleting = status;
    },
    [SET_ITEM_DIALOG](state, status) {
        state.showItemDialog = status;
    },
    [SET_FORM](state, form) {
        state.form = form;
    },

    [SET_REGISTRATION_LIST](state, registrations) {
        state.registrations = registrations;
    },

    [SET_ITEM_SERVICE_TYPE](state, type) {
        state.itemData.serviceType = type;
    }

};