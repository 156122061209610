import { SET_DATA, SET_ITEMS_DATA, SET_ACTIVE_ITEM_ID, TOGGLE_INVOICE_ITEM_FORM, SET_ACTIVE_ITEM, CLEAR_ITEM_DATA, CLEAR_INVOICE_DATA, SET_INVOICE_SAVING_STATE, SET_REGISTRATION_LIST, SET_CREDIT_NOTES } from "./types";
import { fromRFC3339ToDate } from "@rafaeljosem/sq-components/utilities";
import store from "@/store/store";
import { TYPES } from "@/helpers/modules/service/service";

export default {

    fetch({ commit }, options) {
        const repository = this.app.config.globalProperties.$sq.repository;
        const invoice = {};
        return repository.fetch({
            url: `/invoice/${options.id}`
        })
            .then(response => {
                Object.assign(invoice, response);
                if (invoice.contact) {
                    invoice.contact.name = `${invoice.contact.firstname} ${invoice.contact.lastname}`;
                } else {
                    invoice.contact = { firstname: "", lastname: "" }
                }
                invoice.discount = invoice.discount * 100;
                invoice.ncfDate = fromRFC3339ToDate(invoice.ncfDate);
                if (invoice.paymentDate) {
                    invoice.paymentDate = fromRFC3339ToDate(invoice.paymentDate);
                }

                commit(SET_DATA, invoice);
                return invoice;
            })
            .catch(e => {
                console.error(e);
            });

    },

    async submit({ state, getters, dispatch, commit }) {

        if (!state.form) {
            console.error('Invoice form is not set');
            return;
        }
        const repository = this.app.config.globalProperties.$sq.repository;

        const handleSubmit = state.form.handleSubmit(_ => {
            commit(SET_INVOICE_SAVING_STATE, true);
            let promise;
            const data = getters.formattedInvoiceData;

            if (state.invoiceData.id) {
                promise = dispatch('editInvoice', data);

            } else {
                promise = dispatch('createInvoice', data);
            }

            return promise
                .then(_ => {
                    if (!state.invoiceData.id) {
                        state.invoiceData.id = response.data.id;
                    }
                })
                .then(_ => {
                    commit(SET_INVOICE_SAVING_STATE, false);
                })
                .catch(err => {

                    commit(SET_INVOICE_SAVING_STATE, false);
                    state.form.setErrors(err.data.validationErrors)

                    return err;
                });

        });

        const res = await handleSubmit();
        return res;



    },

    createInvoice({ state }, data) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.createRecord(data, {
            url: `/invoice`,
        })
            .then(response => {
                state.invoiceData.id = response.data.id;

            });
    },

    editInvoice({ state }, data) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.editRecord(data, {
            url: `/invoice/${state.invoiceData.id}`,
        });

    },

    fetchItems({ commit }, options) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.fetch({
            url: `/invoice/${options.id}/items`
        })
            .then(response => {

                commit(SET_ITEMS_DATA, response);
                return response;
            })
            .catch(e => {
                console.error(e);
            });

    },

    fetchInvoiceItem({ commit, dispatch }, options) {
        const repository = this.app.config.globalProperties.$sq.repository;

        return repository.fetch({
            url: `/invoice/item/${options.id}`
        })
            .then(response => {

                const item = { ...response };
                item.salesTax = item.salesTax * 100;
                if (item.serviceDate) {
                    item.serviceDate = fromRFC3339ToDate(item.serviceDate);
                }
                if (item.serviceType === TYPES.CONSULTING || item.serviceType === TYPES.AUDITING) {
                    commit(SET_ACTIVE_ITEM, item);
                    return response;

                }

                return dispatch('fetchRegistrations', { id: item.event.id })
                    .then(_ => {
                        commit(SET_ACTIVE_ITEM, item);
                        return item;

                    });
            })

            .catch(e => {
                console.error(e);
            });
    },

    fetchRegistrations({ commit }, options) {
        return store.dispatch('event/fetchRegistrations', { id: options.id, cache: false })
            .then(registrations => {
                registrations.forEach(registration => {
                    registration.companyName = registration.company.name
                });
                commit(SET_REGISTRATION_LIST, registrations)
                return registrations;
            })
            .catch(e => {
                console.error(e);
            });

    },

    deleteItem({ state, dispatch }, id) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.removeRecord({
            url: `/invoice/item/${id}`,
        })
            .then(_ => {
                return dispatch('fetchItems', { id: state.invoiceData.id });
            });
    },

    generateNCF({ state }, options) {
        const repository = this.app.config.globalProperties.$sq.repository;
        const toast = this.app.config.globalProperties.$sq.toast;
        return repository.createRecord({}, {
            url: `/invoice/${options.id}/ncf`,
        })
            .then(response => {
                if (response instanceof Error) {
                    toast.notifyError({
                        detail: response
                    });
                    return;
                }
                state.invoiceData.ncf = response.data.ncf;
                state.ncfStatus = {
                    validUntil: fromRFC3339ToDate(response.data.authorizationValidUntil),
                    maxAuthorization: response.data.maxAuthorizationSequence
                }
            })
            .catch(e => {
                console.error(e);
            });
    },

    generateCreditNote({ state }, options) {
        const repository = this.app.config.globalProperties.$sq.repository;
        const toast = this.app.config.globalProperties.$sq.toast;
        return repository.createRecord({}, {
            url: `/invoice/${options.id}/credit-note`,
        })
            .then(response => {
                if (response instanceof Error) {
                    toast.notifyError({
                        detail: response
                    });
                }
                return response;
            })
            .catch(e => {
                console.error(e);
            });

    },
    getCreditNotes({ commit }, options) {
        const repository = this.app.config.globalProperties.$sq.repository;
        const toast = this.app.config.globalProperties.$sq.toast;
        return repository.fetch({
            url: `/invoice/${options.id}/credit-notes`,
        })
            .then(response => {
                if (response instanceof Error) {
                    toast.notifyError({
                        detail: response
                    });
                }
                commit(SET_CREDIT_NOTES, response);
                return response;
            })
            .catch(e => {
                console.error(e);
            });

    },


    toggleItemDialog({ commit, state }, id = null) {
        if (state.item.showItemDialog) {
            dispatch('clearItemData');
        }
        commit(SET_ACTIVE_ITEM_ID, id);
        commit(TOGGLE_INVOICE_ITEM_FORM);

    },

    openItemDialog({ commit }, id = null) {
        commit(SET_ACTIVE_ITEM_ID, id);
        commit(TOGGLE_INVOICE_ITEM_FORM, true);

    },
    closeItemDialog({ commit, dispatch }) {
        dispatch('clearItemData')
            .then(_ => {
                commit(TOGGLE_INVOICE_ITEM_FORM, false);
            });

    },

    clearItemData({ commit }) {
        commit(CLEAR_ITEM_DATA);
    },

    clear({ commit, dispatch }) {

        commit(CLEAR_INVOICE_DATA);
        return dispatch('clearItemData');
    },
}