
import {
    CLEAR_DATA, CLEAR_FORM, SET_DATA, SET_FORM, SET_COURSE_SAVING_STATE, SET_CATEGORIES
} from "./types";
import sectionActions from './actions/section';
import { addSupportedLocalesToData } from '@/helpers/helpers';
import store from '@/store/store';



export default {

    setForm({ commit }, form) {
        commit(SET_FORM, form);

    },
    fetch({ commit }, options = {}) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.fetch({
            url: `/course/${options.id}`
        })
            .then(response => {
                const courseData = addSupportedLocalesToData(response);
                courseData.categories = courseData.categories.map(category => {
                    return category.id
                });
                commit(SET_DATA, courseData);
                return courseData;

            });

    },

    fetchCategories({ commit }) {
        return store.dispatch('service/fetchCategories', { cacheResults: false })
            .then(response => {
                commit(SET_CATEGORIES, response);
                return response;
            });
    },

    submit({ state, dispatch, getters, commit }) {
        const handleSubmit = state.form.handleSubmit(_ => {
            commit(SET_COURSE_SAVING_STATE, true);
            let promise;
            const data = getters.formattedCourseData;

            if (state.courseData.id) {
                data.append("_method", "put");
                promise = dispatch('editCourse', data);

            } else {
                promise = dispatch('createCourse', data);
            }

            return promise
                .then(_ => {
                    if (!state.courseData.id) {
                        state.courseData.id = response.data.id;
                    }
                })
                .then(_ => {
                    return dispatch('fetch', { id: state.courseData.id });
                })
                .then(_ => {
                    commit(SET_COURSE_SAVING_STATE, false);
                })
                .catch(e => {
                    commit(SET_COURSE_SAVING_STATE, false);
                    state.form.setErrors(e.data.validationErrors);

                    return e;
                });

        });

        return handleSubmit();
    },

    createCourse({ state }, data) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.createRecord(data, {
            url: `/course`,
        })
            .then(response => {
                state.courseData.id = response.data.id;

            });
    },

    editCourse({ state }, data) {
        const repository = this.app.config.globalProperties.$sq.repository;

        /**
         * BEWARE: must use createRecord method since
         * request should be POST in order to 
         * send multipart/form-data with PUT
         */
        return repository.createRecord(data, {
            url: `/course/${state.courseData.id}`,
            config: {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            },
        });


    },

    clear({ commit }) {
        commit(CLEAR_DATA);
    },

    destroy({ commit }) {
        commit(CLEAR_DATA);
        commit(CLEAR_FORM);

    },

    ...sectionActions,
};