export const defaultData = {
    serviceType: null,
    event: {},
    service: {},
    order: {}

};


export const SET_FORM = 'SET_FORM';
export const SET_DATA = 'SET_DATA';
export const SET_NCO_ITEM_SAVING_STATE = 'SET_NCO_SAVING_STATE';
export const SET_NCO_ITEM_DELETING_STATE = 'SET_NCO_DELETING_STATE';
export const CLEAR_NCO_ITEM_DATA = 'CLEAR_NCO_ITEM_DATA';
export const SET_ITEM_DIALOG = 'SET_ITEM_DIALOG';
export const SET_REGISTRATION_LIST = 'SET_REGISTRATION_LIST';
export const SET_ITEM_SERVICE_TYPE = 'SET_ITEM_SERVICE_TYPE';