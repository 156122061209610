
export const CERTIFICATE_TYPES = {
    ATTENDANCE: 0,
    APPROVAL: 1
};

export const ATTENDEE_STATUS = {
    REGISTERED: 1,
    CANCELLED: 0
};
export const REGISTRATION_STATUS = {
    OPEN: 0,
    CREATED: 1,
    PENDING: 2,
    PAID: 3,
    CANCELLED: 4
};

export const parseCertificateType = function (type) {

    switch (parseInt(type)) {
        case CERTIFICATE_TYPES.ATTENDANCE:
            return 'forms.attendee.certificateType.attendance';
        case CERTIFICATE_TYPES.APPROVAL:
            return 'forms.attendee.certificateType.approval';
    }
}
export const parseStatus = function (status) {

    switch (parseInt(status)) {
        case ATTENDEE_STATUS.REGISTERED:
            return 'forms.attendee.status.registered';
        case ATTENDEE_STATUS.CANCELLED:
            return 'forms.attendee.status.cancelled';
    }
};

export const parseRegistrationStatus = function (status) {
    switch (parseInt(status)) {
        case REGISTRATION_STATUS.OPEN:
            return 'forms.registration.status.open';
        case REGISTRATION_STATUS.CREATED:
            return 'forms.registration.status.created';
        case REGISTRATION_STATUS.PENDING:
            return 'forms.registration.status.pending';
        case REGISTRATION_STATUS.PAID:
            return 'forms.registration.status.paid';
        case REGISTRATION_STATUS.CANCELLED:
            return 'forms.registration.status.cancelled';
    }

};

export const getRegistrationStatusBadge = function (status) {

    switch (parseInt(status)) {
        case REGISTRATION_STATUS.OPEN:
            return 'primary';
        case REGISTRATION_STATUS.CREATED:
            return 'info';
        case REGISTRATION_STATUS.PENDING:
            return 'warning';
        case REGISTRATION_STATUS.PAID:
            return 'success';
        case REGISTRATION_STATUS.CANCELLED:
            return 'danger';
        default:
            throw new Error('Invalid status');
    }

};

export const getRegistrationStatusBadgeBasedOnBillingStatus = function (status, isBilled) {

    status = parseInt(status);
    switch (true) {

        case !isBilled:
            return 'danger';
        case status === REGISTRATION_STATUS.OPEN || status === REGISTRATION_STATUS.CREATED || status === REGISTRATION_STATUS.PENDING:
            return 'warning';
        default:
            return getRegistrationStatusBadge(status);

    }

};
export const parseRegistrationStatusBasedOnBillingStatus = function (status, isBilled) {

    status = parseInt(status);
    switch (true) {

        case !isBilled && (REGISTRATION_STATUS.OPEN || status === REGISTRATION_STATUS.CREATED || status === REGISTRATION_STATUS.PENDING):
            return 'forms.registration.status.notBilled';
        case status === REGISTRATION_STATUS.OPEN || status === REGISTRATION_STATUS.CREATED || status === REGISTRATION_STATUS.PENDING:
            return 'forms.registration.status.billed';
        default:
            return parseRegistrationStatus(status);

    }

};

export const getRegistrationStatusTypes = function () {

    const types = [];

    for (const key in REGISTRATION_STATUS) {

        types.push({
            name: parseRegistrationStatus(REGISTRATION_STATUS[key]),
            value: REGISTRATION_STATUS[key]
        })
    }
    return types;

}


export const getCertificateTypes = function () {

    const types = [];

    for (const key in CERTIFICATE_TYPES) {

        types.push({
            name: parseCertificateType(CERTIFICATE_TYPES[key]),
            value: CERTIFICATE_TYPES[key]
        })
    }
    return types;

};

export const getStatusTypes = function () {

    const types = [];

    for (const key in ATTENDEE_STATUS) {

        types.push({
            name: parseStatus(ATTENDEE_STATUS[key]),
            value: ATTENDEE_STATUS[key]
        })
    }
    return types;

};


/**
 * 
 * @param {Array} registrations 
 */
export const groupRegistrations = function (registrations) {

    const groupedRegistrations = [];

    const groupHasRegistration = function (registrationTarget) {
        const index = groupedRegistrations.findIndex(registration =>
            registration.data.company.id === registrationTarget.company.id);

        return index >= 0;

    }

    const addLeaf = function (key, data) {
        groupedRegistrations.push({
            key,
            data,
            leaf: true
        });
    };

    const addTree = function (key, data, children) {
        groupedRegistrations.push({
            key,
            data,
            children
        });
    };

    const filterRegistrations = function (registrationTarget) {
        return registrations.filter(registration =>
            registration.company.id === registrationTarget.company.id);
    }

    for (let i = 0; i < registrations.length; i++) {
        if (groupHasRegistration(registrations[i])) {
            continue;
        }

        const filteredRegistrations = filterRegistrations(registrations[i]);

        if (filteredRegistrations.length === 1) {
            addLeaf(i, filteredRegistrations[0]);
            continue;
        }

        const children = filteredRegistrations.map((registration, index) => {
            const child = { data: registration };
            child.key = `${i}-${index}`;

            return child;
        });

        addTree(i, {
            id: '-',
            company: { id: registrations[i].company.id, name: registrations[i].company.name }
        }, children);

    }
    return groupedRegistrations;
};