import enValidationMessages from '@vee-validate/i18n/dist/locale/en.json';
import esValidationMessages from '@vee-validate/i18n/dist/locale/es.json';
import esCustomValidationMessages from './es/validations';
import enCustomValidationMessages from './en/validations';
import enForms from './en/forms';
import esForms from './es/forms';
import esMenu from './es/menu';
import enMenu from './en/menu';
import enMenuBar from './en/menubar';
import esMenuBar from './es/menubar';
import esNotifications from './es/notifications';
import enNotifications from './en/notifications';
import enErrors from './en/errors';
import esErrors from './es/errors';
import enDialogs from './en/dialogs';
import esDialogs from './es/dialogs';
import esLocales from './es/locales';
import enLocales from './en/locales';
import esDashboard from './es/dashboard';
import enDashboard from './en/dashboard';
export default {
  en: {
    validations: { ...enValidationMessages, ...enCustomValidationMessages },
    ...enForms,
    ...enMenu,
    ...enNotifications,
    ...enMenuBar,
    ...enErrors,
    ...enDialogs,
    ...enLocales,
    ...enDashboard,


  },
  es: {
    validations: { ...esValidationMessages, ...esCustomValidationMessages },
    ...esForms,
    ...esMenu,
    ...esNotifications,
    ...esMenuBar,
    ...esErrors,
    ...esDialogs,
    ...esLocales,
    ...esDashboard,

  },
}
