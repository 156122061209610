import { SET_EDIT_MODE } from "./types"

export default {
    namespaced: true,
    actions: {
        setEditMode({commit}, mode) {
            commit(SET_EDIT_MODE, mode);
        }
    },
    state: {
        editMode: true,
    },
    getters: {},
    mutations: {
        [SET_EDIT_MODE](state, mode) {
            state.editMode = mode;
        },
    },
}