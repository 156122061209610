export const defaultData = {
    company: {

    },
    service: null,
    contact: {

    },
    requestDate: new Date(),
    dueDate: null,
    source: {},
    status: null,
    revision: null,
    currency: null,
    assignedTo: null

};

export const defaultItemData = {
    salesTax: 0.0,
    event: {
        courseName: null,
        id: null

    },
    service: {
        name: null,
        id: null,
    }

};

export const SET_DATA = 'SET_DATA';
export const SET_QUOTE_SAVING_STATE = 'SET_QUOTE_SAVING_STATE';
export const SET_ITEMS_DATA = 'SET_ITEMS_DATA';
export const SET_FORM = 'SET_FORM';
export const SET_ACTIVE_ITEM_ID = 'SET_ACTIVE_ITEM_ID';
export const SET_ACTIVE_ITEM = 'SET_ACTIVE_ITEM';
export const TOGGLE_QUOTE_ITEM_FORM = 'TOGGLE_QUOTE_ITEM_FORM';
export const CLEAR_QUOTE_DATA = 'CLEAR_QUOTE_DATA';
export const CLEAR_ITEM_DATA = 'CLEAR_ITEM_DATA';