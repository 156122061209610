import { ref } from "vue";
import store from "@/store/store";
import { computed } from "@vue/reactivity";
import { TYPES } from "@/helpers/modules/service/service";
import { defineRule } from "vee-validate";
import { required } from "@vee-validate/rules";
import { toRFC3339FromDate } from "@rafaeljosem/sq-components/utilities";

const invoiceData = computed(_ => {
    return store.state.invoice.invoiceData;
});

const itemData = computed(_ => {
    return store.state.invoice.item.activeItemData;
});


export const validationSchema = {
    invoice: null,
    id: null,
    price: "required",
    serviceDate: "requiredIfService",
    quantity: "required",
    description: null,
    event: "requiredIfEvent",
    service: "requiredIfService",
    order: "requiredIfEvent",
    salesTax: "required",
    serviceType: null,
}

defineRule("required", required);
defineRule("requiredIfEvent", function (value) {
    if (itemData.value.serviceType === TYPES.TRAINING) {
        return required(value);
    }

    return true;
});
defineRule("requiredIfService", function (value) {
    if (itemData.value.serviceType === TYPES.CONSULTING || itemData.value.serviceType === TYPES.AUDITING) {
        return required(value);
    }

    return true;
});

/**
 * 
 * @param {Object} args 
 * @param {Object} args.repository
 * @param {Number} args.id
 * @param {Function} args.confirmFunction
 * @param {String} args.deleteActionURL
 * @param {String} args.closeDialogURL
 * @param {Object} args.confirmDialog
 * @param {String} args.confirmDialog.text
 * @param {String} args.confirmDialog.header
 * @returns 
 */
export const deleteInvoiceItem = function (args) {

    const doDelete = async function (id) {


        try {
            await store.dispatch(args.deleteActionURL, id);
            return store.dispatch(args.closeDialogURL);
        } catch (e) {
            console.error(e);
        }
    }

    if (!args.repository.hasNCF()) {
        return doDelete(args.id)
    }

    return args.confirmFunction.require({
        acceptClass: 'p-button-danger',
        header: args.confirmDialog.header,
        message: args.confirmDialog.text,
        accept: () => {
            return doDelete(args.id);
        },
        reject: () => {
            return;
        },
    });
};

export default function (repository, form, { t }, invoiceRepository = null, confirm = null) {


    const isSaving = ref(false);
    const isDeleting = ref(false);



    const submit = function () {
        return handleSubmit();

    };

    //TODO: move to store
    const handleSubmit = form.handleSubmit(_ => {
        isSaving.value = true;
        let promise;
        const data = formatData();

        if (itemData.value.id) {
            promise = editItem(data);

        } else {
            promise = createItem(data);
        }

        return promise
            .then(_ => {
                if (!itemData.value.id) {
                    itemData.value.id = response.data.id;
                }
                return store.dispatch('invoice/fetchItems', { id: invoiceData.value.id });
            })
            .then(_ => {
                isSaving.value = false;
            })
            .catch(e => {
                isSaving.value = false;
                let errorList = Object.assign({}, e.data.validationErrors);
                //Merge event form errors and invoice item form errors
                if (invoiceData.value.service === TYPES.IHT) {
                    errorList = Object.assign(errorList, e.data.validationErrors.event);
                    delete errorList.event;
                }
                form.setErrors(errorList);
            });


    });

    const createItem = function (data) {
        return repository.createRecord(data, {
            url: `/invoice/item`,
        })
            .then(response => {
                itemData.value.id = response.data.id;

            });
    };

    const editItem = function (data) {
        return repository.editRecord(data, {
            url: `/invoice/item/${itemData.value.id}`,
        });

    };


    const formatData = function () {
        const formattedItemData = Object.assign({}, itemData.value);
        formattedItemData.invoice = invoiceData.value.id;
        formattedItemData.salesTax = formattedItemData.salesTax / 100;

        if (formattedItemData.serviceDate) {
            formattedItemData.serviceDate = toRFC3339FromDate(formattedItemData.serviceDate);
        }

        // if (itemData.value.serviceType !== TYPES.CONSULTING && invoiceData.value.service !== TYPES.AUDITING) {

        // }

        if (itemData.value.serviceType === TYPES.TRAINING) {
            formattedItemData.event = formattedItemData.event.id;
            formattedItemData.order = formattedItemData.order.id;
            delete formattedItemData.service;
        }

        if (itemData.value.serviceType === TYPES.CONSULTING || itemData.value.serviceType === TYPES.AUDITING) {
            formattedItemData.service = formattedItemData.service.id;
            delete formattedItemData.event;
            delete formattedItemData.order;
        }
        return deleteFieldsNotPartOfSchema(formattedItemData);
    };

    const deleteFieldsNotPartOfSchema = function (data) {

        const schemaFields = Object.keys(validationSchema);
        const dataFields = Object.keys(data);
        const diff = dataFields.filter(field => !schemaFields.includes(field));

        diff.forEach(field => {
            delete data[field];
        });

        return data;

    };

    const deleteItem = async function (id) {
        isDeleting.value = true;
        try {
            const results = await deleteInvoiceItem({
                id,
                confirmFunction: confirm,
                repository: invoiceRepository,
                deleteActionURL: 'invoice/deleteItem',
                closeDialogURL: 'invoice/closeItemDialog',
                confirmDialog: {
                    text: t('dialogs.invoice.ncf.deleteItem.text'),
                    header: t('dialogs.invoice.ncf.deleteItem.header')
                }
            });
            isDeleting.value = false;
            return results;
        } catch (e) {
            isDeleting.value = false;
            return;
        };
    };

    return {
        submit,
        deleteItem,
        itemData,
        isSaving,
        isDeleting
    }
};