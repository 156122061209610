import { SET_DATA, SET_ITEMS_DATA, SET_ACTIVE_ITEM_ID, TOGGLE_CREDIT_NOTE_ITEM_FORM, SET_ACTIVE_ITEM, CLEAR_ITEM_DATA, CLEAR_CREDIT_NOTE_DATA, SET_CREDIT_NOTE_SAVING_STATE, SET_REGISTRATION_LIST } from "./types";
import { fromRFC3339ToDate } from "@rafaeljosem/sq-components/utilities";
import store from "@/store/store";

export default {

    fetch({ commit }, options) {
        const repository = this.app.config.globalProperties.$sq.repository;
        const credit = {};
        return repository.fetch({
            url: `/credit/${options.id}`
        })
            .then(response => {
                Object.assign(credit, response);
                if (credit.contact) {
                    credit.contact.name = `${credit.contact.firstname} ${credit.contact.lastname}`;
                } else {
                    credit.contact = { firstname: "", lastname: "" }
                }
                credit.ncfDate = fromRFC3339ToDate(credit.ncfDate);

                commit(SET_DATA, credit);
                return credit;
            })
            .catch(e => {
                console.error(e);
            });

    },

    async submit({ state, getters, dispatch, commit }) {

        if (!state.form) {
            console.error('Credit Note form is not set');
            return;
        }

        const handleSubmit = state.form.handleSubmit(_ => {
            commit(SET_CREDIT_NOTE_SAVING_STATE, true);
            let promise;
            const data = getters.formattedCreditNoteData;

            if (state.creditNoteData.id) {
                promise = dispatch('editCreditNote', data);

            } else {
                promise = dispatch('createCreditNote', data);
            }
            return promise
                .then(_ => {
                    if (!state.creditNoteData.id) {
                        state.creditNoteData.id = response.data.id;
                    }
                })
                .then(_ => {
                    commit(SET_CREDIT_NOTE_SAVING_STATE, false);
                })
                .catch(err => {

                    commit(SET_CREDIT_NOTE_SAVING_STATE, false);
                    state.form.setErrors(err.data.validationErrors)

                    return err;
                });

        });

        const res = await handleSubmit();
        return res;



    },

    createCreditNote({ state }, data) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.createRecord(data, {
            url: `/credit`,
        })
            .then(response => {
                state.creditNoteData.id = response.data.id;

            });
    },

    editCreditNote({ state }, data) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.editRecord(data, {
            url: `/credit/${state.creditNoteData.id}`,
        });

    },

    fetchItems({ commit }, options) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.fetch({
            url: `/credit/${options.id}/items`
        })
            .then(response => {
                commit(SET_ITEMS_DATA, response);
                return response;
            })
            .catch(e => {
                console.error(e);
            });

    },

    fetchCreditNoteItem({ commit, dispatch }, options) {
        const repository = this.app.config.globalProperties.$sq.repository;

        return repository.fetch({
            url: `/credit/item/${options.id}`
        })
            .then(response => {
                commit(SET_ACTIVE_ITEM, { ...response });
                return response;
            })

            .catch(e => {
                console.error(e);
            });
    },

    fetchRegistrations({ commit }, options) {
        return store.dispatch('event/fetchRegistrations', { id: options.id, cache: false })
            .then(registrations => {
                registrations.forEach(registration => {
                    registration.companyName = registration.company.name
                });
                commit(SET_REGISTRATION_LIST, registrations)
                return registrations;
            })
            .catch(e => {
                console.error(e);
            });

    },

    async submitItem({ state, getters, dispatch, commit }) {

        if (!state.form) {
            console.error('Credit Note Item form is not set');
            return;
        }

        const handleSubmit = state.form.handleSubmit(async _ => {
            // isSaving.value = true;
            // let promise;
            const data = getters.formattedCreditNoteItemData;

            if (state.item.activeItemData.id) {
                await dispatch('editItem', data);

            } else {
                await dispatch('addItem', data);
            }


            try {
                if (!state.item.activeItemData.id) {
                    state.item.activeItemData.id = response.data.id;
                }
                return dispatch('fetchItems', { id: state.creditNoteData.id });
            } catch (e) {
                console.error(e)
                // isSaving.value = false;
                if (e.data && e.data.validationErrors) {
                    state.form.setErrors(e.data.validationErrors);
                }
                return e;
            }



        });


        const res = await handleSubmit();
        return res;

    },

    editItem({ state }, data) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.editRecord(data, {
            url: `/credit/item/${state.item.activeItemData.id}`,
        }).then(response => {
            if (response instanceof Error) {
                toast.notifyError({
                    detail: response
                });
                return;
            }
            return response;
        });

    },

    addItem({ state }, data) {
        const repository = this.app.config.globalProperties.$sq.repository;
        const toast = this.app.config.globalProperties.$sq.toast;
        return repository.createRecord(data, {
            url: `/credit/item`,
        })
            .then(response => {
                if (response instanceof Error) {
                    toast.notifyError({
                        detail: response
                    });
                    return;
                }
                state.item.activeItemData.id = response.data.id;

            });

    },

    deleteItem({ state, dispatch }, id) {
        const repository = this.app.config.globalProperties.$sq.repository;
        const toast = this.app.config.globalProperties.$sq.toast;
        return repository.removeRecord({
            url: `/credit/item/${id}`,
        })
            .then(response => {

                if (response instanceof Error) {
                    toast.notifyError({
                        detail: response
                    });
                    return;
                }

                return dispatch('fetchItems', { id: state.creditNoteData.id });
            });
    },


    generateNCF({ state }, options) {
        const repository = this.app.config.globalProperties.$sq.repository;
        const toast = this.app.config.globalProperties.$sq.toast;
        return repository.createRecord({}, {
            url: `/credit/${options.id}/ncf`,
        })
            .then(response => {
                if (response instanceof Error) {
                    toast.notifyError({
                        detail: response
                    });
                    return;
                }
                state.creditNoteData.ncf = response.data.ncf;
                state.ncfStatus = {
                    validUntil: fromRFC3339ToDate(response.data.authorizationValidUntil),
                    maxAuthorization: response.data.maxAuthorizationSequence
                }
            })
            .catch(e => {
                console.error(e);
            });
    },

    toggleItemDialog({ commit, state }, id = null) {
        if (state.item.showItemDialog) {
            dispatch('clearItemData');
        }
        commit(TOGGLE_CREDIT_NOTE_ITEM_FORM);

    },

    openItemDialog({ commit }, id) {
        commit(SET_ACTIVE_ITEM_ID, id);
        commit(TOGGLE_CREDIT_NOTE_ITEM_FORM, true);


    },
    closeItemDialog({ commit, dispatch }) {
        dispatch('clearItemData')
            .then(_ => {
                commit(TOGGLE_CREDIT_NOTE_ITEM_FORM, false);
            });

    },

    clearItemData({ commit }) {
        commit(CLEAR_ITEM_DATA);
    },

    clear({ commit, dispatch }) {

        commit(CLEAR_CREDIT_NOTE_DATA);
        return dispatch('clearItemData');
    },
}