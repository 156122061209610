import { CLEAR_ACTION_DATA, SET_ACTION_DELETING_STATE, SET_ACTION_SAVING_STATE, SET_DATA } from "./types";
import { fetch, submit } from "../../action/actions";
import store from "@/store/store";

export default {

    fetch({ commit }, options) {

        return fetch.call(this, {
            url: `qms/nco/action/${options.id}`
        })
            .then(actionData => {
                commit(SET_DATA, actionData);
            });
    },

    submit({ state, commit }) {
        //  const repository = this.app.config.globalProperties.$sq.repository;

        commit(SET_ACTION_SAVING_STATE, true);
        return submit.call(this, {
            actionData: state.actionData,
            url: {
                create: `qms/nco/${store.state.qms.nco.ncoData.id}/action`,
                edit: `qms/nco/action/${state.actionData.id}`
            }
        })
            .then(_ => {
                return store.dispatch('qms/nco/fetchActions');
            })
            .then(_ => {
                commit(SET_ACTION_SAVING_STATE, false);
                return store.dispatch('sq/toast/notifySuccess', {
                    title: 'Success',
                    detail: 'Done!'
                });
            })
            .catch(e => {
                commit(SET_ACTION_SAVING_STATE, false);
                throw e;
            });
    },

    delete({ commit }, options) {
        const repository = this.app.config.globalProperties.$sq.repository;
        commit(SET_ACTION_DELETING_STATE, true);
        return repository.removeRecord({
            url: `/qms/nco/action/${options.id}`,
            showSuccessMessage: false
        })
            .then(_ => {
                return store.dispatch('qms/nco/fetchActions');
            })
            .then(_ => {
                commit(SET_ACTION_DELETING_STATE, false);
                store.dispatch('sq/toast/notifySuccess', {
                    title: "",
                    detail: ""
                })
            });

    },


    clear({ commit }) {
        commit(CLEAR_ACTION_DATA);
    },
}