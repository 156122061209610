import {
    SET_DATA, CLEAR_ACTION_DATA, defaultData, SET_ACTION_SAVING_STATE,SET_ACTION_DELETING_STATE, OPEN_ACTION_DIALOG, CLOSE_ACTION_DIALOG, SET_ACTION
} from "./types";

export default {

    [SET_DATA](state, data) {
        state.actionData = data;
    },
    [CLEAR_ACTION_DATA](state) {
        state.actionData = { ...defaultData };
        state.actionType = null;
    },

    [SET_ACTION_SAVING_STATE](state, status) {
        state.isSaving = status;
    },
    [SET_ACTION_DELETING_STATE](state, status) {
        state.isDeleting = status;
    },

    [OPEN_ACTION_DIALOG](state, actionType) {
        state.showActionDialog = true;
        state.actionType = actionType;
    },
    [CLOSE_ACTION_DIALOG](state) {
        state.showActionDialog = false;
    },

    [SET_ACTION](state, data) {
        const action = JSON.parse(JSON.stringify(data.action));
        action.dueDate = new Date(action.dueDate);
        state.actionData = action;
        state.actionType = data.actionType;
    }

};