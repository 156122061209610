import {
    SET_CAUSE, CLEAR_CAUSE_DATA, defaultData, SET_CAPA_CAUSE_DELETING_STATE, SET_CAPA_CAUSE_SAVING_STATE, OPEN_CAUSE_DIALOG, CLOSE_CAUSE_DIALOG
} from "./types";

export default {

    [SET_CAUSE](state, payload) {
        state.causeData = JSON.parse(JSON.stringify(payload.cause));
    },
    [CLEAR_CAUSE_DATA](state) {
        state.causeData = { ...defaultData };
        state.showCauseDialog = false;
        state.isSaving = false;
        state.isDeleting = false;
    },

    [SET_CAPA_CAUSE_SAVING_STATE](state, status) {
        state.isSaving = status;
    },
    [SET_CAPA_CAUSE_DELETING_STATE](state, status) {
        state.isDeleting = status;
    },
    [OPEN_CAUSE_DIALOG](state) {
        state.showCauseDialog = true;
    },
    [CLOSE_CAUSE_DIALOG](state) {
        state.showCauseDialog = false;
    },

};