import {
    SET_DATA, CLEAR_INSTRUCTOR_DATA, defaultData, SET_INSTRUCTOR_SAVING_STATE, SET_FORM
} from "./types";

export default {

    [SET_DATA](state, data) {
        state.instructorData = data;
    },
    [CLEAR_INSTRUCTOR_DATA](state) {
        state.instructorData = { ...defaultData };
    },

    [SET_INSTRUCTOR_SAVING_STATE](state, status) {
        state.isSaving = status;
    },
    [SET_FORM](state, form) {
        state.form = form;
    }

};