import ncoModule from "./nco/store";
import capaModule from "./capa/store";

export default {
    namespaced: true,
    actions: {},
    state: {},
    mutations: {},
    getters: {},
    modules: {
        nco: ncoModule,
        capa: capaModule
       
    }
}