import { CLEAR_ACCOUNT_DATA, SET_ACCOUNT_SAVING_STATE, SET_DATA } from "./types";

export default {

    fetch({ commit }, options) {
        const repository = this.app.config.globalProperties.$sq.repository;
        const account = {};
        return repository.fetch({
            url: `/account/${options.id}`
        })
            .then(response => {
                Object.assign(account, response);
                commit(SET_DATA, account);
                return account;
            })
            .catch(e => {
                console.error(e);
            });

    },

    submit({ state, getters, dispatch, commit }) {

        if (!state.form) {
            console.error('Invoice form is not set');
            return;
        }
        const repository = this.app.config.globalProperties.$sq.repository;

        const handleSubmit = state.form.handleSubmit(_ => {
            commit(SET_ACCOUNT_SAVING_STATE, true);
            let promise;
            const data = getters.formattedAccountData;

            if (state.accountData.id) {
                promise = dispatch('editAccount', data);

            } else {
                promise = dispatch('createAccount', data);
            }

            return promise
                .then(_ => {
                    if (!state.accountData.id) {
                        state.accountData.id = response.data.id;
                    }
                })
                .then(_ => {
                    commit(SET_ACCOUNT_SAVING_STATE, false);
                })
                .catch(e => {
                    commit(SET_ACCOUNT_SAVING_STATE, false);
                    state.form.setErrors(e.data.validationErrors);

                    return e;
                });

        });

        return handleSubmit();
    },

    createAccount({ state }, data) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.createRecord(data, {
            url: `/account`,
        })
            .then(response => {
                state.accountData.id = response.data.id;

            });
    },

    editAccount({ state }, data) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.editRecord(data, {
            url: `/account/${state.accountData.id}`,
        });

    },

    clear({ commit }) {

        commit(CLEAR_ACCOUNT_DATA);
    },
}