import { defaultEventData } from "./types";

export default {

    eventData: JSON.parse(JSON.stringify(defaultEventData)),
    instructorList: [],
    form: null,
    registration: {
        showRegistrationDialog: false,
        activeRegistrationId: null
    },
    mail: {
        showMailDialog: false,
        activeMailId: null
    },
    mails: [],
    registrations: [],
    attendees: []
}