
import { toRFC3339FromDate } from "@rafaeljosem/sq-components/utilities";
export default {

    formattedNCOData(state) {
        const formattedData = { ...state.ncoData };
        formattedData.ncDate = toRFC3339FromDate(formattedData.ncDate);
        
        if (formattedData.process) {
            formattedData.process = formattedData.process.id;
        }

        if (formattedData.employee) {
            formattedData.employee = formattedData.employee.id;
        }
    
        return formattedData;

    }

};