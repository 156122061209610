import { toRFC3339FromDate } from "@rafaeljosem/sq-components/utilities";

export default {

    formattedInvoiceData(state) {
        const formattedData = Object.assign({}, state.invoiceData);
        formattedData.ncfDate = toRFC3339FromDate(formattedData.ncfDate);
        if (formattedData.paymentDate) {
            formattedData.paymentDate = toRFC3339FromDate(formattedData.paymentDate);
        }
        formattedData.company = formattedData.company.id;
        formattedData.contact = formattedData.contact.id;
        formattedData.discount = formattedData.discount/100;

        //Delete extra fields
        delete formattedData.total;
        delete formattedData.ncf;
        delete formattedData.dateCreated;
        delete formattedData.invoiceType;
        return formattedData;

    }

};