import { SET_DATA, SET_ITEMS_DATA, SET_ACTIVE_ITEM_ID, TOGGLE_QUOTE_ITEM_FORM, SET_ACTIVE_ITEM, CLEAR_QUOTE_DATA, CLEAR_ITEM_DATA, defaultData, defaultItemData, SET_QUOTE_SAVING_STATE, SET_FORM } from "./types";

export default {

    [SET_DATA](state, data) {
        state.quoteData = data;
    },

    [SET_ITEMS_DATA](state, data) {
        state.quoteItems = data;
    },
    [SET_ACTIVE_ITEM_ID](state, id) {
        state.item.activeItemId = id;

    },
    [SET_ACTIVE_ITEM](state, item) {
        state.item.activeItemData = item;
    },
    [TOGGLE_QUOTE_ITEM_FORM](state, status = null) {
        if (status !== null) {
            state.item.showItemDialog = status;
            return;
        }
        state.item.showItemDialog = !state.item.showItemDialog;
    },
    [CLEAR_QUOTE_DATA](state) {
        state.quoteData = defaultData;
        state.quoteItems = [];
    },
    [CLEAR_ITEM_DATA](state) {
        state.item.activeItemData = {...defaultItemData};
        state.item.activeItemId = null;

    },

    [SET_QUOTE_SAVING_STATE](state, status) {
        state.isSaving = status;
    },
    [SET_FORM](state, form) {
        state.form = form;
    },

};