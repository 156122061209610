

export default {

    formattedAccountData(state) {
        const formattedData = { ...state.accountData };
        return formattedData;

    }

};