export const defaultData = {
    employee: {},
};

export const SET_DATA = 'SET_DATA';
export const SET_ACTION_SAVING_STATE = 'SET_ACTION_SAVING_STATE';
export const SET_ACTION_DELETING_STATE = 'SET_ACTION_DELETING_STATE';
export const SET_FORM = 'SET_FORM';
export const CLEAR_ACTION_DATA = 'CLEAR_NCO_DATA';
export const OPEN_ACTION_DIALOG = 'OPEN_ACTION_DIALOG';
export const CLOSE_ACTION_DIALOG = 'CLOSE_ACTION_DIALOG';
export const SET_ACTION = 'SET_ACTION';
