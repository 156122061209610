// axios
import axios from 'axios';

const baseURL = process.env.VUE_APP_SQMS_URL

const instance = axios.create({
  baseURL
  // You can add your headers here
});

instance.interceptors.request.use(config => {

  const token = localStorage.getItem('accessToken');
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

export default instance;
