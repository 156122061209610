import { createRouter, createWebHistory } from 'vue-router';
import { isAuthenticated, hasRole } from '@rafaeljosem/sq-components/plugins/auth/auth';

const routes = [
    {
        path: '/',
        name: 'dashboard',
        exact: true,
        component: () => import('./components/Dashboard.vue'),
        meta: {
            breadcrumb: [{ parent: 'Dashboard', label: 'Dashboard' }],
        },
    },
    {
        path: '/course/:id',
        name: 'view-course',
        component: () => import('@/pages/course/Course.vue'),
        meta: {
            roles: ['ROLE_COURSE_SHOW'],
        }
    },
    {
        path: '/course/create',
        name: 'create-course',
        component: () => import('@/pages/course/Course.vue'),
        meta: {
            roles: ['ROLE_COURSE_SHOW'],
        }
    },
    {
        path: '/course/list',
        name: 'course-list',
        component: () => import('@/pages/course/CourseList.vue'),
        meta: {
            roles: ['ROLE_COURSE_SHOW']
        },
    },
    {
        path: '/event/create',
        name: 'create-event',
        component: () => import('@/pages/event/Event.vue'),
        meta: {
            roles: ['ROLE_EVENT_EDIT'],
        },
    },
    {
        path: '/event/:id',
        name: 'show-event',
        component: () => import('@/pages/event/Event.vue'),
        meta: {
            roles: ['ROLE_EVENT_SHOW'],
        },
    },
    {
        path: '/survey/event/:id',
        name: 'show-survey-form',
        component: () => import('@/pages/survey/SurveyForm.vue'),
        meta: {
            roles: ['ROLE_SURVEY_SHOW'],
        },
    },
    {
        path: '/event/list',
        name: 'event-list',
        component: () => import('@/pages/event/EventList.vue'),
        meta: {
            roles: ['ROLE_EVENT_SHOW'],
        },
    },
    {
        path: '/quote/create',
        name: 'quote-create',
        component: () => import('@/pages/quote/Quote.vue'),
        meta: {
            roles: ['ROLE_QUOTE_EDIT'],
        },
    },
    {
        path: '/quote/:id',
        name: 'show-quote',
        component: () => import('@/pages/quote/Quote.vue'),
        meta: {
            roles: ['ROLE_QUOTE_SHOW'],
        },
    },
    {
        path: '/quote/list',
        name: 'quote-list',
        component: () => import('@/pages/quote/QuoteList.vue'),
        meta: {
            roles: ['ROLE_QUOTE_SHOW'],
        },
    },
    {
        path: '/invoice/create',
        name: 'invoice-create',
        component: () => import('@/pages/invoice/Invoice.vue'),
        meta: {
            roles: ['ROLE_INVOICE_EDIT'],
        },
    },
    {
        path: '/invoice/:id',
        name: 'show-invoice',
        component: () => import('@/pages/invoice/Invoice.vue'),
        meta: {
            roles: ['ROLE_INVOICE_SHOW'],
        },
    },
    {
        path: '/invoice/list',
        name: 'invoice-list',
        component: () => import('@/pages/invoice/InvoiceList.vue'),
        meta: {
            roles: ['ROLE_INVOICE_SHOW'],
        },
    },
    {
        path: '/credit/create',
        name: 'credit-create',
        component: () => import('@/pages/credit/Credit.vue'),
        meta: {
            roles: ['ROLE_CREDIT_EDIT'],
        },
    },
    {
        path: '/credit/list',
        name: 'credit-list',
        component: () => import('@/pages/credit/CreditNoteList.vue'),
        meta: {
            roles: ['ROLE_CREDIT_SHOW'],
        },
    },
    {
        path: '/credit/:id',
        name: 'show-credit-note',
        component: () => import('@/pages/credit/Credit.vue'),
        meta: {
            roles: ['ROLE_INVOICE_SHOW'],
        },
    },
    {
        path: '/account/create',
        name: 'account-create',
        component: () => import('@/pages/account/Account.vue'),
        meta: {
            roles: ['ROLE_COMPANY_EDIT'],
        },
    },
    {
        path: '/account/:id',
        name: 'show-account',
        component: () => import('@/pages/account/Account.vue'),
        meta: {
            roles: ['ROLE_COMPANY_SHOW'],
        },
    },
    {
        path: '/account/list',
        name: 'account-list',
        component: () => import('@/pages/account/AccountList.vue'),
        meta: {
            roles: ['ROLE_COMPANY_SHOW'],
        },
    },
    {
        path: '/contact/create',
        name: 'contact-create',
        component: () => import('@/pages/contact/Contact.vue'),
        meta: {
            roles: ['ROLE_CONTACT_EDIT'],
        },
    },
    {
        path: '/contact/:id',
        name: 'show-contact',
        component: () => import('@/pages/contact/Contact.vue'),
        meta: {
            roles: ['ROLE_CONTACT_SHOW'],
        },
    },
    {
        path: '/contact/list',
        name: 'contact-list',
        component: () => import('@/pages/contact/ContactList.vue'),
        meta: {
            roles: ['ROLE_CONTACT_SHOW'],
        },
    },
    {
        path: '/instructor/create',
        name: 'instructor-create',
        component: () => import('@/pages/instructor/Instructor.vue'),
        meta: {
            roles: ['ROLE_INSTRUCTOR_EDIT'],
        },
    },
    {
        path: '/instructor/:id',
        name: 'show-instructor',
        component: () => import('@/pages/instructor/Instructor.vue'),
        meta: {
            roles: ['ROLE_INSTRUCTOR_SHOW'],
        },
    },
    {
        path: '/instructor/list',
        name: 'instructor-list',
        component: () => import('@/pages/instructor/InstructorList.vue'),
        meta: {
            roles: ['ROLE_INSTRUCTOR_SHOW'],
        },
    },
    {
        path: '/service/create',
        name: 'service-create',
        component: () => import('@/pages/service/Service.vue'),
        meta: {
            roles: ['ROLE_SERVICE_EDIT'],
        },
    },
    {
        path: '/service/:id',
        name: 'show-service',
        component: () => import('@/pages/service/Service.vue'),
        meta: {
            roles: ['ROLE_SERVICE_SHOW'],
        },
    },
    {
        path: '/service/list',
        name: 'service-list',
        component: () => import('@/pages/service/ServiceList.vue'),
        meta: {
            roles: ['ROLE_SERVICE_SHOW'],
        },
    },
    {
        path: '/qms/nco/create',
        name: 'qms-nco-create',
        component: () => import('@/pages/qms/nco/NCO.vue'),
        meta: {
            roles: ['ROLE_QMS_NCO_EDIT'],
        },
    },
    {
        path: '/qms/nco/:id',
        name: 'qms-nco-show',
        component: () => import('@/pages/qms/nco/NCO.vue'),
        meta: {
            roles: ['ROLE_QMS_NCO_SHOW'],
        },
    },
    {
        path: '/qms/nco/list',
        name: 'qms-nco-list',
        component: () => import('@/pages/qms/nco/NCOList.vue'),
        meta: {
            roles: ['ROLE_QMS_NCO_SHOW'],
        },
    },
    {
        path: '/qms/capa/create',
        name: 'qms-capa-create',
        component: () => import('@/pages/qms/capa/CAPA.vue'),
        meta: {
            roles: ['ROLE_QMS_CAPA_EDIT'],
        },
    },
    {
        path: '/qms/capa/:id',
        name: 'qms-capa-show',
        component: () => import('@/pages/qms/capa/CAPA.vue'),
        meta: {
            roles: ['ROLE_QMS_CAPA_SHOW'],
        },
    },
    {
        path: '/qms/capa/list',
        name: 'qms-capa-list',
        component: () => import('@/pages/qms/capa/CAPAList.vue'),
        meta: {
            roles: ['ROLE_QMS_CAPA_SHOW'],
        },
    },


    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {

    // If auth required, check login. If login fails redirect to login page
    // if (to.meta.authRequired) {

    //TODO: When token expired an cant connect it won't go to login page
    if (!isAuthenticated() && to.path !== '/login') {
        router.push({ path: '/login', query: { to: to.path } });
        return;
    }

    if (isAuthenticated() && to.meta.roles) {
        let canGo = false;

        to.meta.roles.some(role => {
            if (hasRole(role)) {
                canGo = true;
                return canGo;
            }
        });

        if (!canGo) {
            router.push({ path: '/access' });
        }
    }
    // }

    return next()

})

router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
});

export default router;