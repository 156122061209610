import { createApp, h } from 'vue';
import { reactive } from 'vue';
import store from '@/store/store';
import router from './router';
import App from './App.vue';
import i18n from "./i18n/i18n";
import Loader from "./components/loader/Loader";
import {
	InputDigit as sqInputNumber, Input as sqInput, Textarea as sqTextarea,
	Button as sqButton, FileUpload as sqUpload, Switch as sqSwitch,
	Select as sqSelect, RadioButton as sqRadioButton, MultiSelect as sqMultiSelect, SearchButton as sqSearchButton, SaveButton as sqSaveButton, ResetButton as sqResetButton, DeleteButton as sqDeleteButton, Dialog as sqDialog, FileInput as sqFileInput,
	ConfirmationService, IconBtn as sqIconBtn, SplitButton as sqSplitButton, Dropdown, Ripple
} from "@rafaeljosem/sq-components/components";
import sqMenu from "./components/menu/Menu";
import sqComponents from "@rafaeljosem/sq-components/main";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import CodeHighlight from './AppCodeHighlight';

//import '@/assets/css/main.css';
//import 'primevue/resources/primevue.min.css';
//import 'primeicons/primeicons.css';
import 'prismjs/themes/prism-coy.css';
//import '@rafaeljosem/sq-components/main.css'
// import '@fullcalendar/core/main.min.css';
// import '@fullcalendar/daygrid/main.min.css';
// import '@fullcalendar/timegrid/main.min.css';





router.beforeEach(function (to, from, next) {
	window.scrollTo(0, 0);
	next();
});

import Access from './pages/Access';
import Error from './pages/Error';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
const app = createApp({
	computed: {
		ViewComponent() {
			switch (this.$route.path) {
				case '/login':
					return Login;
				case '/error':
					return Error;
				case '/access':
					return Access;
				case '/notfound':
					return NotFound;
				default:
					return App;
			}
		}
	},
	render() { return h(this.ViewComponent) }
});

app.config.globalProperties.$appState = reactive({ inputStyle: 'outlined' });
app.config.globalProperties.$primevue = reactive({ ripple: true });

store.app = app; //Can't find any other way to reference current instance in store
app.use(store);
app.use(router);
app.use(i18n);
app.use(ConfirmationService);
app.use(sqComponents, {
	store,
	i18n
});


//app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
app.directive('code', CodeHighlight);
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('sqFileInput', sqFileInput);
app.component('sqMenu', sqMenu);
app.component('sqDialog', sqDialog);
app.component('sqButton', sqButton);
app.component('sqIconBtn', sqIconBtn);
app.component('sqSplitButton', sqSplitButton);
app.component('sqSaveButton', sqSaveButton);
app.component('sqDeleteButton', sqDeleteButton);
app.component('sqResetButton', sqResetButton);
app.component('sqSearchButton', sqSearchButton);
app.component('sqMultiSelect', sqMultiSelect);
app.component('sqRadioButton', sqRadioButton);
app.component('sqSelect', sqSelect);
app.component('sqSwitch', sqSwitch);
app.component('sqUpload', sqUpload);
app.component('sqInputNumber', sqInputNumber);
app.component('sqInput', sqInput);
app.component('sqTextarea', sqTextarea);
app.component('Loader', Loader);
app.component('Dropdown', Dropdown);


app.mount('#app');