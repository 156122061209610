import { CLEAR_CAPA_DATA, SET_CAPA_SAVING_STATE, SET_DATA } from "./types";
import { fromRFC3339ToDate } from "@rafaeljosem/sq-components/utilities";
import {formatActions} from "@/store/modules/qms/action/actions";

export default {

    fetch({ commit }, options) {
        const repository = this.app.config.globalProperties.$sq.repository;
        const capa = {};
        return repository.fetch({
            url: `/qms/capa/${options.id}`
        })
            .then(response => {
                Object.assign(capa, response);
                capa.requestedBy.name = `${capa.requestedBy.firstname} ${capa.requestedBy.lastname}`;
                capa.requestDate = fromRFC3339ToDate(capa.requestDate);
                capa.estClosureDate = fromRFC3339ToDate(capa.estClosureDate);
                commit(SET_DATA, capa);
                return capa;
            })
            .catch(e => {
                console.error(e);
            });

    },

    fetchCauses({ state }) {

        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.fetch({
            url: `/qms/capa/${state.capaData.id}/causes`
        })
            .then(response => {
                state.causes = [...response];
                return response;
            })
            .catch(e => {
                console.error(e);
            });

    },
    fetchSources({ state }) {

        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.fetch({
            url: `/qms/capa/sources`
        })
            .then(response => {
                state.sources = [...response];
                return response;
            })
            .catch(e => {
                console.error(e);
            });

    },

    fetchActions({ state }) {

        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.fetch({
            url: `/qms/capa/${state.capaData.id}/actions`
        })
            .then(response => {
                const actions = {...response};
                formatActions(actions.inmediateActions);
                formatActions(actions.permanentActions);
                state.actions = actions;
                return response;
            })
            .catch(e => {
                console.error(e);
            });

    },

    submit({ state, getters, dispatch, commit }) {

        if (!state.form) {
            console.error('Contact form is not set');
            return;
        }
        const repository = this.app.config.globalProperties.$sq.repository;

        const handleSubmit = state.form.handleSubmit(_ => {
            commit(SET_CAPA_SAVING_STATE, true);
            let promise;
            const data = getters.formattedCAPAData;

            if (state.capaData.id) {
                promise = dispatch('editCAPA', data);

            } else {
                promise = dispatch('createCAPA', data);
            }

            return promise
                .then(_ => {
                    if (!state.capaData.id) {
                        state.capaData.id = response.data.id;
                    }
                })
                .then(_ => {
                    commit(SET_CAPA_SAVING_STATE, false);
                })
                .catch(e => {
                    commit(SET_CAPA_SAVING_STATE, false);
                    state.form.setErrors(e.data.validationErrors);
                    return e;
                });

        });

        return handleSubmit();
    },

    createCAPA({ state }, data) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.createRecord(data, {
            url: `/qms/capa`,
        })
            .then(response => {
                state.capaData.id = response.data.id;

            });
    },

    editCAPA({ state }, data) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.editRecord(data, {
            url: `/qms/capa/${state.capaData.id}`,
        });

    },

    linkNCO({state}, options) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.createRecord({}, {
            url: `/qms/capa/${state.capaData.id}/nco/${options.id}`,
        });
    },

    clear({ commit, state }) {

        commit(CLEAR_CAPA_DATA);
        state.form.resetForm();
    },
}