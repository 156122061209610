import { defaultData, defaultItemData } from "./types";

export default {
    quoteData: {...defaultData},
    quoteItems: [],
    isSaving: false,
    form: null,
    item: {
        showItemDialog: false,
        activeItemId: null,
        activeItemData: {...defaultItemData}
    }
}