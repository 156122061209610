import store from "@/store/store";
import { toRFC3339FromDate } from "@rafaeljosem/sq-components/utilities";

export default {

    formattedQuoteData(state) {
        const formattedData = Object.assign({}, state.quoteData);
        if (formattedData.dueDate) {
            formattedData.dueDate = toRFC3339FromDate(formattedData.dueDate);
        }
        if (formattedData.deliveryDate) {
            formattedData.deliveryDate = toRFC3339FromDate(formattedData.deliveryDate);
        }
        if (formattedData.requestDate) {
            formattedData.requestDate = toRFC3339FromDate(formattedData.requestDate);
        }
        //TODO: it should be selected from an employee's list
        formattedData.assignedTo = store.state.sq.auth.appActiveUser.id;
        formattedData.company = formattedData.company.id;
        formattedData.contact = formattedData.contact.id;
        formattedData.source = formattedData.source.id;

        //Delete extra fields
        delete formattedData.total;
        delete formattedData.subtotal;
        delete formattedData.salesTaxTotal;
        delete formattedData.createdBy;
        delete formattedData.items;
        delete formattedData.code;
        delete formattedData.sentDate;
        delete formattedData.createdDate;
        return formattedData;

    }

};