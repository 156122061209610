import {
    defaultEventData,
    SET_DATA,
    SET_FORM, CLEAR_FORM, CLEAR_DATA, SET_ACTIVE_REGISTRATION,
    TOGGLE_REGISTRATION_FORM, SET_REGISTRATION_LIST, SET_ATTENDEE_LIST, SET_MAIL_LIST, SET_ACTIVE_MAIL, TOGGLE_MAIL_FORM,
    SET_INSTRUCTOR_LIST
} from "./types";

export default {
    [SET_DATA](state, data) {
        state.eventData = data;
    },
    [CLEAR_DATA](state) {
        state.eventData = JSON.parse(JSON.stringify(defaultEventData));
        state.instructorList = [];
        state.registrations = [];
        state.attendees = [];
        if (state.form) {
            state.form.resetForm();
        }

    },
    [SET_FORM](state, form) {
        state.form = form;
    },
    [CLEAR_FORM](state) {
        state.form = null;
    },
    [SET_ACTIVE_REGISTRATION](state, id) {
        state.registration.activeRegistrationId = id;
    },
    [TOGGLE_REGISTRATION_FORM](state) {
        state.registration.showRegistrationDialog = !state.registration.showRegistrationDialog;
    },
    [SET_REGISTRATION_LIST](state, registrations) {
        state.registrations = registrations;
    },
    [SET_ATTENDEE_LIST](state, attendees) {
        state.attendees = attendees;
    },
    [SET_MAIL_LIST](state, mails) {
        state.mails = mails;
    },
    [SET_INSTRUCTOR_LIST](state, instructors) {
        state.instructorList = instructors;
    },
    [SET_ACTIVE_MAIL](state, id) {
        state.mail.activeMailId = id;
    },
    [TOGGLE_MAIL_FORM](state) {
        state.mail.showMailDialog = !state.mail.showMailDialog;
    }
}