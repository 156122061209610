import {
    defaultServiceData,
    SET_DATA,
    SET_FORM, CLEAR_FORM, CLEAR_DATA, SET_CATEGORIES, SET_SERVICE_SAVING_STATE, SET_FORM_LANGUAGE
} from "./types";

export default {
    [SET_DATA](state, data) {
        state.serviceData = data;
    },
    [SET_CATEGORIES](state, data) {
        state.categories = data;
    },
    [CLEAR_DATA](state) {
        state.serviceData = JSON.parse(JSON.stringify(defaultServiceData));
        if (state.form) {
            state.form.resetForm();
        }

    },
    [SET_FORM](state, form) {
        state.form = form;
    },
    [CLEAR_FORM](state) {
        state.form = null;
    },

    [SET_SERVICE_SAVING_STATE](state, value) {
        state.isSaving = value;
    },

    [SET_FORM_LANGUAGE](state, language) {
        state.formLanguage = language;
    }
}