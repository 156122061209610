
import store from "@/store/store";
export default {

    formattedCAPACauseData(state) {
        const formattedData = { ...state.causeData };
        formattedData.capa = store.state.qms.capa.capaData.id;
        return formattedData;

    }

};