import {
    SET_DATA, CLEAR_CONTACT_DATA, defaultData, SET_CONTACT_SAVING_STATE, SET_FORM
} from "./types";

export default {

    [SET_DATA](state, data) {
        state.contactData = data;
    },
    [CLEAR_CONTACT_DATA](state) {
        state.contactData = { ...defaultData };
    },

    [SET_CONTACT_SAVING_STATE](state, status) {
        state.isSaving = status;
    },
    [SET_FORM](state, form) {
        state.form = form;
    }

};