import { CLEAR_INSTRUCTOR_DATA, SET_INSTRUCTOR_SAVING_STATE, SET_DATA } from "./types";
import { SET_DATA as SET_CONTACT_DATA } from "@/store/modules/contact/types";
import store from "@/store/store";

export default {

    fetch({ commit }, options) {
        const repository = this.app.config.globalProperties.$sq.repository;
        const instructor = {};
        return repository.fetch({
            url: `/instructor/${options.id}`
        })
            .then(response => {
                Object.assign(instructor, response);
                commit(SET_DATA, instructor);
                store.commit(`contact/${SET_CONTACT_DATA}`, instructor.contact);
                return instructor;
            })
            .catch(e => {
                console.error(e);
            });

    },

    submit({ state, getters, dispatch, commit }) {

        if (!state.form) {
            console.error('Instructor form is not set');
            return;
        }
        const repository = this.app.config.globalProperties.$sq.repository;

        const handleSubmit = state.form.handleSubmit(_ => {
            commit(SET_INSTRUCTOR_SAVING_STATE, true);
            let promise;
            const data = getters.formattedInstructorData;

            if (state.instructorData.id) {
                data.append("_method", "put");
                promise = dispatch('editInstructor', data);

            } else {
                promise = dispatch('createInstructor', data);
            }

            return promise
                .then(_ => {
                    if (!state.instructorData.id) {
                        state.instructorData.id = response.data.id;
                    }
                })
                .then(_ => {
                    commit(SET_INSTRUCTOR_SAVING_STATE, false);
                })
                .catch(e => {
                    commit(SET_INSTRUCTOR_SAVING_STATE, false);
                    state.form.setErrors(e.data.validationErrors)
                    state.form.setErrors(e.data.validationErrors.contact)
                    return e;
                });

        });

        return handleSubmit();
    },

    createInstructor({ state }, data) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.createRecord(data, {
            url: `/instructor`,
        })
            .then(response => {
                state.instructorData.id = response.data.id;

            });
    },

    editInstructor({ state }, data) {
        const repository = this.app.config.globalProperties.$sq.repository;

        /**
         * BEWARE: must use createRecord method since
         * request should be POST in order to 
         * send multipart/form-data with PUT
         */
        return repository.createRecord(data, {
            url: `/instructor/${state.instructorData.id}`,
            config: {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            },
        });


    },

    clear({ commit, state }) {
        commit(CLEAR_INSTRUCTOR_DATA);
        state.form.resetForm();
        store.dispatch('contact/clear');
    },
}