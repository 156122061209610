<template>
  <div
    :class="containerClass"
    :data-theme="colorScheme"
    @click="onDocumentClick($event)"
  >
    <div class="layout-content-wrapper">
      <AppTopBar
        :topbarNotificationMenuActive="topbarNotificationMenuActive"
        :topbarUserMenuActive="topbarUserMenuActive"
        @menu-button-click="onMenuButtonClick"
        @search-click="toggleSearch"
        @topbar-notification="onTopbarNotificationMenuButtonClick"
        @topbar-user-menu="onTopbarUserMenuButtonClick"
        @right-menu-click="onRightMenuButtonClick"
        @right-menubutton-click="onRightMenuButtonClick"
      ></AppTopBar>
      <loader></loader>
      <toast></toast>
      <div class="layout-content">
        <router-view :key="$route.fullPath" />
      </div>
      <AppFooter />
    </div>

    <AppMenu
      :model="menu"
      :layoutMode="layoutMode"
      :active="menuActive"
      :mobileMenuActive="staticMenuMobileActive"
      @menu-click="onMenuClick"
      @menuitem-click="onMenuItemClick"
      @root-menuitem-click="onRootMenuItemClick"
    ></AppMenu>

    <AppRightMenu
      :rightMenuActive="rightMenuActive"
      @right-menu-click="onRightMenuClick"
    ></AppRightMenu>

    <AppConfig
      v-model:configActive="configActive"
      v-model:layoutMode="layoutMode"
      v-model:menuTheme="menuTheme"
      v-model:colorScheme="colorScheme"
      @config-click="onConfigClick"
      @config-button-click="onConfigButtonClick"
    ></AppConfig>

    <AppSearch
      :searchActive="searchActive"
      @search-click="onSearchClick"
      @search-hide="onSearchHide"
    />

    <div class="layout-mask modal-in"></div>
  </div>
</template>

<script>
import EventBus from "./event-bus";
import AppTopBar from "./AppTopbar";
import AppFooter from "./AppFooter";
import AppConfig from "./AppConfig";
import AppMenu from "./AppMenu";
import AppSearch from "./AppSearch";
import AppRightMenu from "./AppRightMenu";
//import { init } from "./auth/jwt";
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import {
  faHome,
  faUser,
  faChartLine,
  faCalendarAlt,
  faChalkboardTeacher,
  faDollarSign,
  faBriefcase,
  faCheck,
  faPowerOff,
} from "@fortawesome/free-solid-svg-icons";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
library.add(
  faHome,
  faUser,
  faChartLine,
  faCalendarAlt,
  faChalkboardTeacher,
  faDollarSign,
  faBriefcase,
  faCheck,
  faPowerOff
);
dom.watch();
export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();
    const menu = [
      {
        label: "Favorites",
        items: [
          {
            label: t("menu.home"),
            icon: { lib: "fas", name: "home" },
            to: "/",
          },
        ],
      },
      { separator: true },
      {
        label: "Apps",

        items: [
          {
            label: t("menu.contacts"),
            icon: { lib: "fas", name: "user" },
            items: [
              {
                label: t("menu.createAccount"),
                to: "/account/create",
              },
              {
                label: t("menu.showAccounts"),
                to: "/account/list",
              },
              {
                label: t("menu.createContact"),
                to: "/contact/create",
              },
              {
                label: t("menu.showContacts"),
                to: "/contact/list",
              },
            ],
          },
          {
            label: t("menu.sales"),
            icon: { lib: "fas", name: "chart-line" },
            items: [
              {
                label: t("menu.createQuote"),
                to: "/quote/create",
              },
              {
                label: t("menu.showQuotes"),
                to: "/quote/list",
              },
            ],
          },
          {
            label: t("menu.events"),
            icon: { lib: "fas", name: "calendar-alt" },
            items: [
              {
                label: t("menu.createEvent"),
                to: "/event/create",
              },
              {
                label: t("menu.showEvents"),
                to: "/event/list",
              },
            ],
          },
          {
            label: t("menu.instructors"),
            icon: { lib: "fas", name: "chalkboard-teacher" },
            items: [
              {
                label: t("menu.createInstructor"),
                to: "/instructor/create",
              },
              {
                label: t("menu.showInstructors"),
                to: "/instructor/list",
              },
            ],
          },
          {
            label: t("menu.invoices"),
            icon: { lib: "fas", name: "dollar-sign" },
            items: [
              {
                label: t("menu.createInvoice"),
                to: "/invoice/create",
              },
              {
                label: t("menu.showInvoices"),
                to: "/invoice/list",
              },
              {
                label: t("menu.createCreditNote"),
                to: "/credit/create",
              },
              {
                label: t("menu.showCreditNotes"),
                to: "/credit/list",
              },
            ],
          },
          {
            label: t("menu.services"),
            icon: { lib: "fas", name: "briefcase" },
            items: [
              {
                label: t("menu.createCourse"),
                to: "/course/create",
              },
              {
                label: t("menu.showCourses"),
                to: "/course/list",
              },
              {
                label: t("menu.createService"),
                to: "/service/create",
              },
              {
                label: t("menu.showServices"),
                to: "/service/list",
              },
            ],
          },
          {
            label: t("menu.qualityManagement"),
            icon: { lib: "fas", name: "check" },
            items: [
              {
                label: t("menu.NCOs"),
                items: [
                  {
                    label: t("menu.createNCO"),
                    to: "/qms/nco/create",
                  },
                  {
                    label: t("menu.showNCOs"),
                    to: "/qms/nco/list",
                  },
                ],
              },
              {
                label: t("menu.CAPAs"),
                items: [
                  {
                    label: t("menu.createCAPA"),
                    to: "/qms/capa/create",
                  },
                  {
                    label: t("menu.CAPAs"),
                    to: "/qms/capa/list",
                  },
                ],
              },
            ],
          },
        ],
      },
      { separator: true },
      {
        label: "Settings",
        items: [
          {
            label: t("menu.logout"),
            icon: { lib: "fas", name: "power-off" },
            command: async (_) => {
              await store.dispatch("sq/auth/logout");
              router.push("login");
            },
          },
        ],
      },
    ];

    return {
      menu,
    };
  },
  data() {
    return {
      menuActive: false,
      layoutMode: "static",
      colorScheme: "light",
      menuTheme: "layout-sidebar-blue",
      overlayMenuActive: false,
      staticMenuDesktopInactive: false,
      staticMenuMobileActive: false,
      menuClick: false,
      searchActive: false,
      searchClick: false,
      userMenuClick: false,
      topbarUserMenuActive: false,
      notificationMenuClick: false,
      topbarNotificationMenuActive: false,
      rightMenuClick: false,
      rightMenuActive: false,
      configActive: false,
      configClick: false,
    };
  },
  computed: {
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-slim": this.layoutMode === "slim",
          "layout-sidebar-dim": this.colorScheme === "dim",
          "layout-sidebar-dark": this.colorScheme === "dark",
          "layout-overlay-active": this.overlayMenuActive,
          "layout-mobile-active": this.staticMenuMobileActive,
          "layout-static-inactive":
            this.staticMenuDesktopInactive && this.layoutMode === "static",
          "p-input-filled": this.$appState.inputStyle === "filled",
          "p-ripple-disabled": !this.$primevue.ripple,
        },
        this.colorScheme === "light" ? this.menuTheme : "",
      ];
    },
  },
  components: {
    AppTopBar,
    AppFooter,
    AppConfig,
    AppMenu,
    AppSearch,
    AppRightMenu,
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  methods: {
    onDocumentClick() {
      if (!this.searchClick && this.searchActive) {
        this.onSearchHide();
      }

      if (!this.userMenuClick) {
        this.topbarUserMenuActive = false;
      }

      if (!this.notificationMenuClick) {
        this.topbarNotificationMenuActive = false;
      }

      if (!this.rightMenuClick) {
        this.rightMenuActive = false;
      }

      if (!this.menuClick) {
        if (this.isSlim()) {
          EventBus.emit("reset-active-index");
          this.menuActive = false;
        }

        if (this.overlayMenuActive || this.staticMenuMobileActive) {
          this.hideOverlayMenu();
        }

        this.unblockBodyScroll();
      }

      if (this.configActive && !this.configClick) {
        this.configActive = false;
      }

      this.searchClick = false;
      this.configClick = false;
      this.userMenuClick = false;
      this.rightMenuClick = false;
      this.notificationMenuClick = false;
      this.menuClick = false;
    },
    onMenuClick() {
      this.menuClick = true;
    },
    onMenuButtonClick(event) {
      this.menuClick = true;
      this.topbarUserMenuActive = false;
      this.topbarNotificationMenuActive = false;
      this.rightMenuActive = false;

      if (this.isOverlay()) {
        this.overlayMenuActive = !this.overlayMenuActive;
      }

      if (this.isDesktop()) {
        this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
      } else {
        this.staticMenuMobileActive = !this.staticMenuMobileActive;
        if (this.staticMenuMobileActive) {
          this.blockBodyScroll();
        } else {
          this.unblockBodyScroll();
        }
      }

      event.preventDefault();
    },
    onMenuItemClick(event) {
      if (!event.item.items) {
        EventBus.emit("reset-active-index");
        this.hideOverlayMenu();
      }
      if (!event.item.items && this.isSlim()) {
        this.menuActive = false;
      }
    },
    onRootMenuItemClick() {
      this.menuActive = !this.menuActive;
    },
    onTopbarUserMenuButtonClick(event) {
      this.userMenuClick = true;
      this.topbarUserMenuActive = !this.topbarUserMenuActive;

      this.hideOverlayMenu();

      event.preventDefault();
    },
    onTopbarNotificationMenuButtonClick(event) {
      this.notificationMenuClick = true;
      this.topbarNotificationMenuActive = !this.topbarNotificationMenuActive;

      this.hideOverlayMenu();

      event.preventDefault();
    },
    toggleSearch() {
      this.searchActive = !this.searchActive;
      this.searchClick = true;
    },
    onSearchClick() {
      this.searchClick = true;
    },
    onSearchHide() {
      this.searchActive = false;
      this.searchClick = false;
    },
    onRightMenuClick() {
      this.rightMenuClick = true;
    },
    onRightMenuButtonClick(event) {
      this.rightMenuClick = true;
      this.rightMenuActive = !this.rightMenuActive;
      this.hideOverlayMenu();
      event.preventDefault();
    },
    onConfigClick() {
      this.configClick = true;
    },
    onConfigButtonClick() {
      this.configActive = !this.configActive;
      this.configClick = true;
    },
    hideOverlayMenu() {
      this.overlayMenuActive = false;
      this.staticMenuMobileActive = false;
      this.unblockBodyScroll();
    },
    blockBodyScroll() {
      if (document.body.classList) {
        document.body.classList.add("blocked-scroll");
      } else {
        document.body.className += " blocked-scroll";
      }
    },
    unblockBodyScroll() {
      if (document.body.classList) {
        document.body.classList.remove("blocked-scroll");
      } else {
        document.body.className = document.body.className.replace(
          new RegExp(
            "(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
      }
    },
    isSlim() {
      return this.layoutMode === "slim";
    },
    isOverlay() {
      return this.layoutMode === "overlay";
    },
    isDesktop() {
      return window.innerWidth > 991;
    },
  },
  created() {
    // init();
  },
};
</script>

<style lang="scss">
@import "App.scss";
</style>
