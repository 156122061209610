import { defaultData, defaultItemData } from "./types";

export default {
    creditNoteData: { ...defaultData },
    creditNoteItems: [],
    isSaving: false,
    form: null,
    ncfStatus: {},
    item: {
        showItemDialog: false,
        activeItemId: null,
        activeItemData: { ...defaultItemData }
    }
}