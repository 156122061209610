import { defaultData } from "./types";

export default {
    capaData: { ...defaultData },
    form: null,
    isSaving: false,
    causes: [],
    actions: { permanentActions: [], inmediateActions: [] },
    sources: [],
    showNCOListDialog: false,
}