const Vimeo = require("vimeo").Vimeo;

/**
 * 
 * @param {File} file 
 * @param {Object} params 
 */
const VideoManager = function () {

};

/**
 * 
 * @param {File} file 
 * @param {Object} params 
 * @param {String} params.name
 * @param {String} [params.description = undefined]
 */
const VimeoManager = function (repository) {
    VideoManager.call(this);

    this.client = new Vimeo();
    let accessToken = null;

    this._fetchAccessToken = async function () {
        if (accessToken) {
            return;
        }

        const response = await repository.fetch({
            url: '/course/resource/vimeo'
        });

        accessToken = response.token;
        this.client.setAccessToken(accessToken);

        return accessToken;
    };

    /**
     * Vimeo doesn't provide a valid JSON string :@!!
     * @param {String} errorStr 
     */
    this._getErrorFromString = function (errorStr) {

        const e = JSON.parse(errorStr.substring(errorStr.indexOf('{'), errorStr.length - 1));
        const error = new Error(e.error);
        error.code = e.error_code;
        error.developerMessage = e.developer_message;

        if (e.invalid_parameters) {
            error.invalidParameters = [];
            e.invalid_parameters.forEach(param => {
                error.invalidParameters.push({
                    field: param.field,
                    code: param.error_code,
                    message: param.error,
                    developerMessage: param.developerMessage,
                    link: param.link
                });
            })
        }

        return error;
    }

}

VimeoManager.prototype = VideoManager.prototype;
VimeoManager.prototype.constructor = VimeoManager;

/**
 * 
 * @param {File} file 
 * @param {Object} params
 * @param {String} params.name 
 * @param {String} [params.description = undefined] 
 * @param {Function} [updateProgressCallback = undefined] 
 */
VimeoManager.prototype.upload = async function (file, params, updateProgressCallback) {

    await this._fetchAccessToken();
    const self = this;
    const doUpload = function () {
        return new Promise((resolve, reject) => {
            self.client.upload(
                file,
                params,
                function (uri) {
                    // Get the metadata response from the upload and log out the Vimeo.com url
                    self.client.request(uri + '?fields=link', function (error, body, statusCode, headers) {
                        if (error) {
                            throw error;
                            // return
                        }

                        // return { body, statusCode, headers };
                        resolve(body, statusCode, headers);
                        // Make an API call to edit the title and description of the video.

                    })
                },
                function (bytesUploaded, bytesTotal) {
                    if (!updateProgressCallback) {
                        return;
                    }
                    const percentage = (bytesUploaded / bytesTotal * 100).toFixed(2);
                    updateProgressCallback(bytesUploaded, bytesTotal, percentage + '%');
                    //console.log(bytesUploaded, bytesTotal, percentage + '%')
                },
                function (e) {
                    reject(self._getErrorFromString(e))
                    return;
                }
            )
        })
    };


    return doUpload();
};

/**
 * 
 * @param {String} uri 
 */
VimeoManager.prototype.delete = async function (uri) {
    const videoId = uri.match(/\d+/);
    await this._fetchAccessToken();

    if (!videoId || videoId.length !== 1) {
        return Promise.reject(new Error("Invalid video URI"));
    }

    return new Promise((resolve, reject) => {
        this.client.request({
            method: "DELETE",
            path: `/videos/${videoId[0]}`
        }, function (error, body, statusCode, headers) {
            if (error) {
                const err = new Error(error);
                err.body = body;
                err.statusCode = statusCode;
                err.headers = headers;
                reject(err);
                return;
            }

            resolve({ body, statusCode, headers });
        })
    })
}

/**
 * 
 * @param {String} uri 
 * @param {Array} field 
 */
VimeoManager.prototype.getField = function (uri, fields) {
    return new Promise((resolve, reject) => {
        this.client.request({
            method: "GET",
            path: `${uri}?fields=${fields.join(',')}`
        }, function (error, body, statusCode, headers) {
            if (error) {
                reject(error);
                return;
            }

            resolve(body, statusCode, headers);
        })
    })
}

/**
 * 
 * @param {String} method 
 * @param {String} uri 
 * @param {Object} params 
 */
VimeoManager.prototype.request = function (method, uri, params) {
    return new Promise((resolve, reject) => {
        this.client.request({
            method: method,
            path: uri,
            params: params
        }, function (error, body, statusCode, headers) {
            if (error) {
                reject(error);
                // console.log('There was an error making the request.')
                // console.log('Server reported: ' + error)
                return
            }

            //console.log('The title and description for ' + uri + ' has been edited.')
            resolve(body, statusCode, headers);

        });
    });
};

/**
 * 
 * @param {String} uri 
 */
VimeoManager.prototype.checkTranscodeStatus = function (uri) {
    // Make an API call to see if the video is finished transcoding.
    return new Promise((resolve, reject) => {
        client.request(
            uri + '?fields=transcode.status',
            function (error, body, statusCode, headers) {
                if (error) {
                    reject(error);
                    return
                }

                //console.log('The transcode status for ' + uri + ' is: ' + body.transcode.status)
                resolve(body.transcode.status, statusCode, headers);
            }
        )
    });
};

export const VideoManagerFactory = (function () {

    /**
     * 
     * @param {String} name 
     */
    const create = function (name, repository) {
        if (name.toUpperCase() === 'VIMEO') {
            return new VimeoManager(repository);
        }

    };

    return {
        create: create,
    }
})();