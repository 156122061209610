export const SET_DATA = 'SET_SERVICE_DATA';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_FORM = 'SET_FORM';
export const CLEAR_DATA = 'CLEAR_SERVICE_DATA';
export const SET_SERVICE_SAVING_STATE = 'SET_SERVICE_SAVING_STATE';
export const CLEAR_FORM = 'CLEAR_SERVICE_FORM';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_FORM_LANGUAGE = 'SET_FORM_LANGUAGE';
export const defaultServiceData = {

    id: null,
    categories: [],
    translations: {
        es: { name: null, showWebsite: null },
        en: { name: null, showWebsite: null },
    },



};