import {toRFC3339FromDate } from "@rafaeljosem/sq-components/utilities";
export default {

    formattedEventData(state) {
        const formattedData = Object.assign({}, state.eventData);
        formattedData.startDate = toRFC3339FromDate(formattedData.startDate);
        formattedData.endDate = toRFC3339FromDate(formattedData.endDate);
        formattedData.course = formattedData.course.id;
        formattedData.company = formattedData.company.id;
        return formattedData;

    }

}