export const ACTION_STATUS = {
    OPEN_STATUS: 0,
    IN_PROGRESS_STATUS: 1,
    CLOSED_STATUS: 2,
};

export const ACTION_TYPES = {
    CORRECTIVE_ACTION: 0,
    INMEDIATE_ACTION: 1
};


export const parseActionStatus = function (type) {

    switch (parseInt(type)) {
        case ACTION_STATUS.OPEN_STATUS:
            return `forms.qms.action.status.open`;
        case ACTION_STATUS.IN_PROGRESS_STATUS:
            return `forms.qms.action.status.inProgress`;
        case ACTION_STATUS.CLOSED_STATUS:
            return `forms.qms.action.status.closed`;
        default:
            return ``;
    }

};

export const getActionStatusBadge = function(status) {

    switch (parseInt(status)) {
        case ACTION_STATUS.OPEN_STATUS:
            return 'danger';
        case ACTION_STATUS.IN_PROGRESS_STATUS:
            return 'warning';
        case ACTION_STATUS.CLOSED_STATUS:
            return 'success';
        default:
            return 'primary';
    }

};


export const getActionStatus = function () {

    const status = [];

    for (const key in ACTION_STATUS) {

        status.push({
            name: parseActionStatus(ACTION_STATUS[key]),
            value: ACTION_STATUS[key]
        })
    }
    return status;
};