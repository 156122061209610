import { validationSchema } from "@/pages/credit/composable/useCreditNoteItemRepository";
import { toRFC3339FromDate } from "@rafaeljosem/sq-components/utilities";

export default {

    formattedCreditNoteData(state) {
        const formattedData = Object.assign({}, state.creditNoteData);
        formattedData.ncfDate = toRFC3339FromDate(formattedData.ncfDate);
        formattedData.company = formattedData.company.id;
        formattedData.contact = formattedData.contact.id;
        //formattedData.invoice = formattedData.invoice.id;

        //Delete extra fields
        delete formattedData.total;
        delete formattedData.ncf;
        delete formattedData.dateCreated;
        delete formattedData.invoiceType;

        return formattedData;

    },

    formattedCreditNoteItemData(state) {


        const deleteFieldsNotPartOfSchema = function (data) {

            const schemaFields = Object.keys(validationSchema);
            const dataFields = Object.keys(data);
            const diff = dataFields.filter(field => !schemaFields.includes(field));

            diff.forEach(field => {
                delete data[field];
            });

            return data;

        };

        const formattedItemData = Object.assign({}, state.item.activeItemData);
        formattedItemData.creditNote = state.creditNoteData.id;
        formattedItemData.salesTax = formattedItemData.salesTax / 100;

        return deleteFieldsNotPartOfSchema(formattedItemData);

    }

};