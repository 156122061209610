<template>
  <div
    class="
      tw-min-h-screen tw-flex tw-items-center tw-justify-center
      bg-color
      tw-py-12 tw-px-4
      sm:tw-px-6
      lg:tw-px-8
    "
  >
    <div
      class="
        tw-flex tw-items-center tw-justify-center tw-bg-white
        sm:tw-w-3/4
        xl:tw-w-1/3
        tw-p-10 tw-rounded-lg tw-shadow-md
      "
    >
      <div class="tw-max-w-md tw-w-full tw-space-y-8">
        <div>
          <img
            class="tw-mx-auto tw-w-auto"
            src="@/assets/images/brand/logo.jpg"
            alt="Workflow"
          />
          <h2
            class="
              tw-mt-6
              tw-text-center
              tw-text-3xl
              tw-font-extrabold
              tw-text-gray-900
            "
          >
            Suprema Qualitas
          </h2>
          <h4 class="tw-text-center tw-text-xl tw-text-gray-900">
            Panel de Administración
          </h4>
        </div>

        <input type="hidden" name="remember" value="true" />
        <div class="tw-rounded-md tw-shadow-sm tw--space-y-px">
          <div class="tw-mb-5">
            <label for="email-address" class="tw-font-bold">Usuario</label>
            <input
              v-model="username"
              type="email"
              autocomplete="email"
              required
              class="
                tw-appearance-none
                tw-rounded-md
                tw-relative
                tw-block
                tw-w-full
                tw-p-3
                tw-border
                tw-border-gray-300
                tw-placeholder-gray-500
                tw-text-gray-900
                tw-rounded-t-md
                focus:tw-outline-none
                focus:tw-ring-blue-500
                focus:tw-border-blue-500
                focus:tw-z-10
                sm:tw-text-sm
              "
            />
          </div>
          <div>
            <label for="password" class="tw-font-bold">Contraseña</label>
            <input
              v-model="password"
              type="password"
              autocomplete="current-password"
              required
              class="
                tw-appearance-none
                tw-rounded-md
                tw-relative
                tw-block
                tw-w-full
                tw-p-3
                tw-border
                tw-border-gray-300
                tw-placeholder-gray-500
                tw-text-gray-900
                tw-rounded-b-md
                focus:tw-outline-none
                focus:tw-ring-blue-500
                focus:tw-border-blue-500
                focus:tw-z-10
                sm:tw-text-sm
              "
            />
          </div>
        </div>

        <!-- <div class="tw-flex tw-items-center tw-justify-between">
          <div class="tw-flex tw-items-center">
            <input
              id="remember_me"
              name="remember_me"
              type="checkbox"
              class="tw-h-4 tw-w-4 tw-text-blue-600 tw-focus:ring-blue-500 tw-border-gray-300 tw-rounded"
            />
            <label
              for="remember_me"
              class="tw-ml-2 tw-block tw-text-sm tw-text-gray-900"
            >
              Recordarme
            </label>
          </div>

          <div class="tw-text-sm">
            <a
              href="#"
              class="tw-font-medium tw-text-blue-600 hover:tw-text-blue-500"
            >
              ¿Olvidaste tu clave?
            </a>
          </div>
        </div> -->

        <div>
          <button
            @click="login"
            type="submit"
            class="
              tw-group
              tw-relative
              tw-w-full
              tw-flex
              tw-justify-center
              tw-py-2
              tw-px-4
              tw-border
              tw-border-transparent
              tw-text-sm
              tw-font-medium
              tw-rounded-md
              tw-text-white
              login-btn
              focus:tw-outline-none
              focus:tw-ring-2
              focus:tw-ring-offset-2
              focus:tw-ring-blue-500
            "
          >
            <span
              class="
                tw-absolute
                tw-left-0
                tw-inset-y-0
                tw-flex
                tw-items-center
                tw-pl-3
              "
            >
              <!-- Heroicon name: lock-closed -->
              <svg
                class="tw-h-5 tw-w-5 tw-text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
            Acceder
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="login-body">
		<div class="login-wrapper">
			<div class="login-panel">
				<img src="assets/layout/images/logo-dark.svg" class="logo" alt="diamond-layout" />

				<div class="login-form">
					<h2>Create Your Free Account</h2>
					<p>Already have an account? <a href="/">Login</a></p>
					<InputText id="name" placeholder="Full Name" />
					<InputText id="email" placeholder="Email" />
					<Password placeholder="Password" />
					<Button label="CONTINUE" type="button"></Button>
				</div>

				<p>A problem? <a href="/">Click here</a> and let us help you.</p>
			</div>
			<div class="login-image">
				<div class="login-image-content">
					<h1>Access to your</h1>
					<h1>Diamond</h1>
					<h1>Account</h1>
					<h3>
						Lorem ipsum dolor sit amet, consectetur <br />
						adipiscing elit. Donec posuere velit nec enim <br />
						sodales, nec placerat erat tincidunt.
					</h3>
				</div>
				<div class="image-footer">
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
					<div class="icons">
						<i class="pi pi-github"></i>
						<i class="pi pi-twitter"></i>
					</div>
				</div>
			</div>
		</div>
	</div> -->
</template>

<script >
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from 'vue-router';
export default {
  setup() {
    const username = ref(null);
    const password = ref(null);
    const rememberMe = ref(null);
    const store = useStore();
    const router = useRouter();

    const login = async function () {
      const payload = {
        rememberMe,
        userDetails: { username: username.value, password: password.value },
      };

      try {
        await store.dispatch("sq/auth/login", payload);
        router.push(router.currentRoute.value.query.to || "/");
      } catch (e) {
        //TODO: wrong pass alert
      }
    };

    return { username, password, login, rememberMe };
  },
};
</script>

<style scoped>
.bg-color {
  background-color: #0b62a4;
}

.login-btn {
  background-color: #0b62a4;
}
</style>