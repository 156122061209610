export const defaultData = {
    requestedBy: {},
    process: {},
    source: {},
};

export const SET_DATA = 'SET_DATA';
export const SET_CAPA_SAVING_STATE = 'SET_CAPA_SAVING_STATE';
export const SET_FORM = 'SET_FORM';
export const CLEAR_CAPA_DATA = 'CLEAR_CAPA_DATA';
export const OPEN_NCO_DIALOG = 'OPEN_NCO_DIALOG';
export const CLOSE_NCO_DIALOG = 'CLOSE_NCO_DIALOG';
