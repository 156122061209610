import { CLEAR_CAUSE_DATA, SET_CAPA_CAUSE_SAVING_STATE, SET_CAUSE } from "./types";
import store from '@/store/store';

export default {

    fetch({ commit }, options) {
        const repository = this.app.config.globalProperties.$sq.repository;
        const cause = {};
        return repository.fetch({
            url: `/qms/capa/cause/${options.id}`
        })
            .then(response => {
                Object.assign(cause, response);
                commit(SET_CAUSE, cause);
                return cause;
            })
            .catch(e => {
                console.error(e);
            });

    },

    submit({ state, getters, dispatch, commit }) {

        commit(SET_CAPA_CAUSE_SAVING_STATE, true);
        let promise;
        const data = getters.formattedCAPACauseData;

        if (state.causeData.id) {
            promise = dispatch('editCAPACause', data);

        } else {
            promise = dispatch('createCAPACause', data);
        }

        return promise
            .then(response => {
                if (!state.causeData.id) {
                    state.causeData.id = response.data.id;
                }
            })
            .then(_ => {
                commit(SET_CAPA_CAUSE_SAVING_STATE, false);
                return store.dispatch('sq/toast/notifySuccess', {
                    title: 'Success',
                    detail: 'Done!'
                });
            })
            .catch(e => {
                commit(SET_CAPA_CAUSE_SAVING_STATE, false);
                if (e.data && e.data.validationErrors) {
                    e.errorList = e.data.validationErrors;
                }
                throw e;
            });
    },

    createCAPACause({ state }, data) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.createRecord(data, {
            url: `/qms/capa/cause`,
            showSuccessMessage: false
        })
            .then(response => {
                state.causeData.id = response.data.id;

            });
    },

    editCAPACause({ state }, data) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.editRecord(data, {
            url: `/qms/capa/cause/${state.causeData.id}`,
            showSuccessMessage: false
        });

    },

    delete({ }, options) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.removeRecord({
            url: `/qms/capa/cause/${options.id}`,
            showSuccessMessage: false
        })
            .then(_ => {
                return store.dispatch('qms/capa/fetchCauses');
            })
            .then(_ => {
                store.dispatch('sq/toast/notifySuccess', {
                    title: "",
                    detail: ""
                })
            });

    },

    clear({ commit }) {

        commit(CLEAR_CAUSE_DATA);
    },
}