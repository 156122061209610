export const defaultData = {
    company: {

    },
    contact: {

    },
    discount: 0.0,
    ncfDate: new Date(),
    paymentDate: null,
    status: null,
    currency: null,

};

export const defaultItemData = {
    salesTax: 0.0,
    price: null,
    quantity: null,
    event: {
        courseName: null,
        id: null

    },
    service: {
        name: null,
        id: null,
    },
    order: {
        id: null,
        company: null
    }

};

export const SET_DATA = 'SET_DATA';
export const SET_INVOICE_SAVING_STATE = 'SET_QUOTE_SAVING_STATE';
export const SET_ITEMS_DATA = 'SET_ITEMS_DATA';
export const SET_FORM = 'SET_FORM';
export const SET_ACTIVE_ITEM_ID = 'SET_ACTIVE_ITEM_ID';
export const SET_ACTIVE_ITEM = 'SET_ACTIVE_ITEM';
export const TOGGLE_INVOICE_ITEM_FORM = 'TOGGLE_QUOTE_ITEM_FORM';
export const CLEAR_INVOICE_DATA = 'CLEAR_QUOTE_DATA';
export const CLEAR_ITEM_DATA = 'CLEAR_ITEM_DATA';
export const SET_REGISTRATION_LIST = 'SET_REGISTRATION_LIST';
export const SET_ITEM_SERVICE_TYPE = 'SET_ITEM_SERVICE_TYPE';
export const SET_CREDIT_NOTES = 'SET_CREDIT_NOTES';
export const TOGGLE_CREDIT_NOTES = 'TOGGLE_CREDIT_NOTES';
export const CLEAR_CREDIT_NOTES = 'CLEAR_CREDIT_NOTES';