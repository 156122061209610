<template>
  <div>
    <sq-button
      v-if="popup"
      type="button"
      :label="label"
      @click="toggle"
      aria-haspopup="true"
      aria-controls="overlay-menu"
    />
    <prime-menu
      class="tw-absolute"
      @click="toggle"
      id="overlay-menu"
      ref="menu"
      v-if="show"
      :model="model"
    />
  </div>
</template>
<script>
import PrimeMenu from "primevue/menu";
import { ref } from "vue";
export default {
  name: "Menu",
  components: { PrimeMenu },
  props: {
    id: {
      type: String,
      default: undefined,
      required: false,
    },
    model: {
      type: Object,
      required: true,
    },

    popup: {
      type: Boolean,
      default: false,
      required: false,
    },

    label: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  setup(props) {
    const show = ref(!props.popup);
    const toggle = function () {
      show.value = !show.value;
    };

    return {
      show,
      toggle,
    };
  },
};
</script>