import {
    SET_DATA, CLEAR_NCO_DATA, defaultData, SET_NCO_SAVING_STATE, SET_FORM, SET_SERVICE_DIALOG, SET_EVENT_DIALOG, SET_REGISTRATION_LIST, SET_ITEM_SERVICE_TYPE
} from "./types";

export default {

    [SET_DATA](state, data) {
        state.ncoData = data;
    },
    [CLEAR_NCO_DATA](state) {
        state.ncoData = { ...defaultData };
    },

    [SET_NCO_SAVING_STATE](state, status) {
        state.isSaving = status;
    },
 
    [SET_FORM](state, form) {
        state.form = form;
    },

};