
import { fromRFC3339ToDate } from "@rafaeljosem/sq-components/utilities";
import { groupRegistrations } from "@/helpers/modules/registration/registrations";
import {
    CLEAR_DATA, CLEAR_FORM, SET_DATA, SET_FORM, TOGGLE_REGISTRATION_FORM,
    SET_ACTIVE_REGISTRATION, SET_REGISTRATION_LIST, SET_ATTENDEE_LIST,
    SET_MAIL_LIST, SET_ACTIVE_MAIL, TOGGLE_MAIL_FORM, SET_INSTRUCTOR_LIST
} from "./types";

export default {

    setForm({ commit }, form) {
        commit(SET_FORM, form);

    },
    fetch({ commit }, options = {}) {
        const repository = this.app.config.globalProperties.$sq.repository;
        if (typeof options.cacheResults === 'undefined') {
            options.cacheResults = true;
        }

        return repository.fetch({
            url: `/event/${options.id}`
        })
            .then(response => {
                const eventData = {};
                Object.assign(eventData, response);
                eventData.startDate = fromRFC3339ToDate(eventData.startDate);
                eventData.endDate = fromRFC3339ToDate(eventData.endDate);
                eventData.instructors = eventData.instructors.map(instructor => {
                    return instructor.id
                });

                if (options.cacheResults) {
                    commit(SET_DATA, eventData);
                }
                return eventData;

            });

    },

    fetchMails({ commit }, eventId) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.fetch({
            url: `/event/${eventId}/mails`
        })
            .then(response => {
                commit(SET_MAIL_LIST, response);
            });

    },

    fetchRegistrations({ commit }, options) {
        const repository = this.app.config.globalProperties.$sq.repository;
        if (Number.isInteger(options)) {
            options = { id: options };
        }
        return repository.fetch({
            url: `/event/${options.id}/registrations`
        })
            .then(response => {
                if (!options.cache || options.cache === true) {
                    commit(SET_REGISTRATION_LIST, groupRegistrations(response));
                }
                return response;
            });
    },
    fetchAttendees({ commit, state }) {
        const repository = this.app.config.globalProperties.$sq.repository; 
        return repository.fetch({
            url: `/event/${state.eventData.id}/attendee/list.json`
        })
            .then(response => {
                response.forEach(attendee => {
                    //Easier manipulation in select boxes
                    attendee.name = attendee.contact.firstname + ' ' + attendee.contact.lastname;
                    attendee.contactId = attendee.contact.id;
                })
                commit(SET_ATTENDEE_LIST, response);
            });
    },

    fetchInstructorList({ commit }) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.fetch({
            url: `/instructors?fields=firstname,lastname,id`
        })
            .then(response => {
                commit(SET_INSTRUCTOR_LIST, response);

            });

    },

    sendCertificates({state}) {

        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.createRecord({},{
            url: `/event/${state.eventData.id}/certificates`
        });
        
    },

    submit({ state, dispatch }) {
        return state.form.handleSubmit(_ => {
            return state.form.validate()
                .then(results => {

                    if (!results.valid) {
                        return;
                    }

                    return dispatch('doSubmit');
                    //doSubmit();
                });

        })();

    },

    doSubmit({ dispatch, getters, state }) {
        const repository = this.app.config.globalProperties.$sq.repository;
        let promise;

        const data = getters.formattedEventData;

        if (state.eventData.id) {
            promise = dispatch('edit', data);

        } else {
            promise = dispatch('create', data);
        }

        return promise
            .catch(e => {
                state.form.setErrors(e.data.validationErrors);
            });

    },

    edit({ state }, data) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.editRecord(data, {
            url: `/event/${state.eventData.id}`,
        });

    },

    create({ state }, data) {
        const repository = this.app.config.globalProperties.$sq.repository;
        return repository.createRecord(data, {
            url: `/event`,
        })
            .then(response => {
                state.eventData.id = parseInt(response.data.id);
            });
    },

    clear({ commit }) {
        commit(CLEAR_DATA);
    },

    destroy({ commit }) {
        commit(CLEAR_DATA);
        commit(CLEAR_FORM);

    },

    toggleRegistrationDialog({ commit }, id = null) {
        commit(SET_ACTIVE_REGISTRATION, id);
        commit(TOGGLE_REGISTRATION_FORM);

    },
    toggleMailDialog({ commit }, id = null) {
        commit(SET_ACTIVE_MAIL, id);
        commit(TOGGLE_MAIL_FORM);

    },
    setActiveRegistrationId({ commit }, id = null) {
        commit(SET_ACTIVE_REGISTRATION, id);
    }
}