import { CLEAR_ACTION_DATA, SET_ACTION_DELETING_STATE, SET_ACTION_SAVING_STATE, SET_DATA } from "./types";
import { fetch, submit } from "../../action/actions";
import store from "@/store/store";
import { ACTION_TYPES } from "@/helpers/modules/qms/action/action";

export default {

    fetch({ commit }, options) {

        return fetch.call(this, {
            url: `qms/capa/action/${options.id}`
        })
            .then(actionData => {
                commit(SET_DATA, actionData);
            });
    },

    submit({ state, commit }) {


        let url = {
            create: `qms/capa/${store.state.qms.capa.capaData.id}/action/inmediate-action`,
            edit: `qms/action-plan/action/${state.actionData.id}`
        }

        if (state.actionType === ACTION_TYPES.CORRECTIVE_ACTION) {

            url = {
                create: `qms/capa/${store.state.qms.capa.capaData.id}/action/permanent-action`,
                edit: `qms/action-plan/action/${state.actionData.id}`
            }

        }

        //  const repository = this.app.config.globalProperties.$sq.repository;
        commit(SET_ACTION_SAVING_STATE, true);
        return submit.call(this, {
            actionData: state.actionData,
            url
        })
            .then(response => {
                if (response instanceof Error) {
                    throw response;
                }
                commit(SET_ACTION_SAVING_STATE, false);
                return store.dispatch('sq/toast/notifySuccess', {
                    title: 'Success',
                    detail: 'Done!'
                });
            })
            .catch(e => {
                commit(SET_ACTION_SAVING_STATE, false);
                throw e;
            });

    },

    delete({ commit }, options) {
        const repository = this.app.config.globalProperties.$sq.repository;
        commit(SET_ACTION_DELETING_STATE, true);
        return repository.removeRecord({
            url: `/qms/capa/action/${options.id}`,
            showSuccessMessage: false
        })
            .then(_ => {
                return store.dispatch('qms/capa/fetchActions');
            })
            .then(_ => {
                commit(SET_ACTION_DELETING_STATE, false);
                store.dispatch('sq/toast/notifySuccess', {
                    title: "",
                    detail: ""
                })
            });

    },


    clear({ commit, state }) {

        commit(CLEAR_ACTION_DATA);
    },
}