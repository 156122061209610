import {
    SET_DATA, CLEAR_CAPA_DATA, defaultData, SET_CAPA_SAVING_STATE, SET_FORM, OPEN_NCO_DIALOG, CLOSE_NCO_DIALOG
} from "./types";

export default {

    [SET_DATA](state, data) {
        state.capaData = data;
    },
    [CLEAR_CAPA_DATA](state) {
        state.capaData = { ...defaultData };
    },

    [SET_CAPA_SAVING_STATE](state, status) {
        state.isSaving = status;
    },

    [SET_FORM](state, form) {
        state.form = form;
    },

    [OPEN_NCO_DIALOG](state) {
        state.showNCOListDialog = true;
    },
    [CLOSE_NCO_DIALOG](state) {
        state.showNCOListDialog = false;
    },

};