import {
    SET_DATA, SET_ITEMS_DATA, SET_ACTIVE_ITEM_ID, TOGGLE_INVOICE_ITEM_FORM, SET_ACTIVE_ITEM, CLEAR_INVOICE_DATA, CLEAR_ITEM_DATA,
    defaultData, CLEAR_CREDIT_NOTES, defaultItemData, SET_INVOICE_SAVING_STATE, SET_FORM, SET_REGISTRATION_LIST, SET_ITEM_SERVICE_TYPE, SET_CREDIT_NOTES, TOGGLE_CREDIT_NOTES
} from "./types";

export default {

    [SET_DATA](state, data) {
        state.invoiceData = data;
    },

    [SET_ITEMS_DATA](state, data) {
        state.invoiceItems = data;
    },
    [SET_ACTIVE_ITEM_ID](state, id) {
        state.item.activeItemId = id;

    },
    [SET_ACTIVE_ITEM](state, item) {
        state.item.activeItemData = item;
    },
    [TOGGLE_INVOICE_ITEM_FORM](state, status = null) {
        if (status !== null) {
            state.item.showItemDialog = status;
            return;
        }
        state.item.showItemDialog = !state.item.showItemDialog;
    },
    [CLEAR_INVOICE_DATA](state) {
        state.invoiceData = defaultData;
        state.invoiceItems = [];
        state.ncfStatus = {};
    },
    [CLEAR_ITEM_DATA](state) {
        state.item.activeItemData = { ...defaultItemData };
        state.item.activeItemId = null;
        state.item.registrations = [];

    },

    [SET_INVOICE_SAVING_STATE](state, status) {
        state.isSaving = status;
    },
    [SET_FORM](state, form) {
        state.form = form;
    },
    [SET_REGISTRATION_LIST](state, registrations) {
        state.item.registrations = registrations;
    },
    [SET_ITEM_SERVICE_TYPE](state, type) {
        state.item.activeItemData.serviceType = type;
    },
    [SET_CREDIT_NOTES](state, creditNotes) {
        state.creditNotes = creditNotes;
    },
    [TOGGLE_CREDIT_NOTES](state, status) {
        if (typeof status !== 'undefined') {
            state.showCreditNoteList = status;
            return;
        }

        state.showCreditNoteList = !state.showCreditNoteList;
        return;
    },

    [CLEAR_CREDIT_NOTES](state) {
        state.creditNotes = [];
    },

};