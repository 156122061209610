<template>
    <div class="layout-footer">
        <div class="footer-logo-container">
            <img id="footer-logo" src="@/assets/images/brand/logo.jpg" alt="sq-logo" />
            <span class="app-name">Suprema Qualitas - Panel de Administración</span>
        </div>
        <span class="copyright">&#169; Suprema Qualitas - {{year}}</span>
    </div>
</template>

<script>
export default {
    name: "AppFooter",
    setup() {
        const year = (new Date()).getFullYear().toString();

        return {
            year
        }
    }
};
</script>